import { useCookies } from 'vue3-cookies';
import axiosInstance from '@/middlewares/interceptor.js'

const { cookies } = useCookies();


export default {
    async getAllPermissions() {
        try {
            let app_tenant = cookies.get("app_tenant");
            const response = await axiosInstance.get(`api/${app_tenant}/permissions?page=0&limit=0`);
            return response;
        } catch (error) {
            console.error('Error al obtener todos los permisos:', error);
            throw error;
        }
    },

    async getModulePermissions(SubmoduleId) {
        try {
            let app_tenant = cookies.get("app_tenant");
            const response = await axiosInstance.get(`api/${app_tenant}/modules/${SubmoduleId}/permissions`);
            return response;
        } catch (error) {
            console.error('Error fetching from API profile/get:', error);
            throw error;
        }
    },
    async createProfile(profileData) {
        try {
            let app_tenant = cookies.get("app_tenant");
            const response = await axiosInstance.post(`api/${app_tenant}/roles/createrolepermissions`, profileData);
            return response;
        } catch (error) {
            console.error('Error al crear el Perfil:', error);
            throw error;
        }
    },

    async getModuleById(moduleId) {
        try {
            let app_tenant = cookies.get("app_tenant");
            const response = await axiosInstance.get(`api/${app_tenant}/modules/${moduleId}`);
            return response;
        } catch (error) {
            console.error('Error al obtener el módulo:', error);
            throw error;
        }
    }
};
