<template>
  <navbar btn-background="bg-gradient-dark" :dark-mode="true" />
  <main class="main-content mt-0 ps">
    <div>
      <section class="min-vh-80 d-flex align-items-center">
        <div class="container">
          <div class="row mt-lg-0 mt-2">
            <div class="col-lg-5 my-auto">
              <h1
                  class="display-3 text-bolder text-gradient text-warning fadeIn1 fadeInBottom mt-5"
              >
                Algo salió mal..
              </h1>
              <h2 class="fadeIn3 fadeInBottom opacity-8">
                No posee permisos para acceder a esta sección
              </h2>
              <p class="lead opacity-6 fadeIn2 fadeInBottom">
                Comuniquese con un administrador del sistema de {{ tenantId }}.
              </p>

            </div>
            <div class="col-lg-5 my-auto">
              <img
                  class="w-80 fadeIn1 fadeInBottom"
                  src="@/assets/img/illustrations/error-500.png"
                  alt="500-error"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="ps__rail-x" style="left: 0px; bottom: 0px">
      <div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px"></div>
    </div>
    <div class="ps__rail-y" style="top: 0px; right: 0px">
      <div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 0px"></div>
    </div>
  </main>
  <app-footer />
</template>

<script>
import { useCookies } from 'vue3-cookies';

export default {
  name: "Home",
  data() {
    return {
      tenantId: null,
    };
  },
  mounted() {
    const { cookies } = useCookies();
    const tenant_id = cookies.get('tenant_id');
    const app_tenant = cookies.get('app_tenant');
    this.tenantId = app_tenant == 'central' ? 'Central' : tenant_id;
  },
};
</script>
