<template>
  <nav>
  </nav>
</template>
<script>
/*eslint-disable*/
import downArrWhite from "@/assets/img/down-arrow-white.svg";
import downArrBlack from "@/assets/img/down-arrow-dark.svg";
import Office from "../../components/Icon/Office.vue";
import Shop from "../../components/Icon/Shop.vue";
import CustomerSupport from "../../components/Icon/CustomerSupport.vue";
import GettingStarted from "../../components/Icon/GettingStarted.vue";

export default {
  name: "Navbar",
  components: {
    Office,
    Shop,
    CustomerSupport,
    GettingStarted,
  },
  props: {
    btnBackground: {
      type: String,
      default: "",
    },
    isBlur: {
      type: String,
      default: "",
    },
    darkMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      downArrWhite,
      downArrBlack
    };
  },
  computed: {
    darkModes() {
      return {
        "text-dark": this.darkMode
      };
    }
  }
};
</script>
