<template>
  <div class="overflow-hidden card move-on-hover">
    <div class="card-body">
      <div>
        Tenant ID: {{ tenantId }}
      </div>
    </div>
  </div>
</template>

<script>
import { useCookies } from 'vue3-cookies';

export default {
  name: "Home",
  data() {
    return {
      tenantId: null,
    };
  },
  mounted() {
    const { cookies } = useCookies();
    const tenant_id = cookies.get('tenant_id');
    const app_tenant = cookies.get('app_tenant');
    this.tenantId = app_tenant == 'central' ? 'Central' : tenant_id;
  },
};
</script>
