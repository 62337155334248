import { defineStore } from 'pinia';
import editOrganizationService from '@/services/organizations/organizationedit.service';

export const useEditOrganizationStore = defineStore({
    id: 'editOrganization',
    state: () => ({
        selectedOrganization: null,
    }),
    actions: {
        async fetchOrganizationById(organizationId) {
            const response = await editOrganizationService.getOrganizationById(organizationId);
            this.selectedOrganization = response.data.data;
        },
        async updateOrganization(organizationId, organizationData) {
            await editOrganizationService.updateOrganization(organizationId, organizationData);
        },
    },
});

