import {defineStore} from 'pinia';
import userOrganizationAdminService from '@/services/users/usersOrganizationAdmin.service';

export const useUserOrganizationAdminStore = defineStore('UserOrganizationAdmin', {
    state: () => ({
        userOrganizationsAdmin: [],
        userOrganizationsAdminPaginator: [],
        userOrganizationsAdminPrincipal: null,
        userOrganizationsAdminSelected: null,
    }),
    getters: {
        getOrganizationsAdmin: (state) => state.userOrganizationAdminService,
    },
    actions: {

        async fetchUserOrganizationsAdmin(idOrganization, page, rows) {
            const response = await userOrganizationAdminService.getUserOrganizationsAdmin(idOrganization, page, rows);
            this.userOrganizationsAdmin = response.data.data.items;

            this.userOrganizationsAdmin.forEach(user => {
                user.is_active = user.is_active === 1;
            });


            this.userOrganizationsAdminPrincipal = this.userOrganizationsAdmin.find(user => user.main_tenant_admin === 1);
            this.userOrganizationsAdminPaginator = response.data.data.pagination;
        },
        async setUserOrganizationAdmin(OrganizationId, UserId) {

            await userOrganizationAdminService.setUserOrganizationAdmin(OrganizationId, UserId);

            this.userOrganizationsAdmin.forEach(user => {
                user.main_tenant_admin = (user.id === UserId) ? 1 : 0;
            });

            this.userOrganizationsAdminPrincipal = this.userOrganizationsAdmin.find(user => user.id === UserId);

        },
        async enableUserOrganizationAdmin(OrganizationId, UserId) {

            await userOrganizationAdminService.enableUserOrganizationAdmin(OrganizationId, UserId);
            this.userOrganizationsAdminSelected = this.userOrganizationsAdmin.find(user => user.id === UserId);
            this.userOrganizationsAdminSelected.is_active = true;

        },
        async disableUserOrganizationAdmin(OrganizationId, UserId) {

            await userOrganizationAdminService.disableUserOrganizationAdmin(OrganizationId, UserId);
            this.userOrganizationsAdminSelected = this.userOrganizationsAdmin.find(user => user.id === UserId);
            this.userOrganizationsAdminSelected.is_active = false;

        }

    }
});
