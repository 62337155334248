import { defineStore } from 'pinia';
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";
import { auth } from "./auth/auth.module";

export const useMainStore = defineStore('main', {
  state: () => ({
    hideConfigButton: false,
    isPinned: true,
    showConfig: false,
    isTransparent: "",
    isRTL: false,
    color: "",
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    navbarFixed: "position-sticky blur shadow-blur left-auto top-1 z-index-sticky px-0 mx-4",
    absolute: "position-absolute px-4 mx-0 w-100 z-index-2",
    bootstrap,
    message: null,
  }),
  modules: {
    auth,
  },
  actions: {
    navbarMinimize() {
      const sidenav_show = document.querySelector(".g-sidenav-show");
      if (sidenav_show.classList.contains("g-sidenav-hidden")) {
        sidenav_show.classList.remove("g-sidenav-hidden");
        sidenav_show.classList.add("g-sidenav-pinned");
        this.isPinned = true;
      } else {
        sidenav_show.classList.add("g-sidenav-hidden");
        sidenav_show.classList.remove("g-sidenav-pinned");
        this.isPinned = false;
      }
    },
    sidebarType(payload) {
      this.isTransparent = payload;
    },
    cardBackground(payload) {
      this.color = payload;
    },
    navbarFixed() {
      this.isNavFixed = !this.isNavFixed;
    },
    toggleEveryDisplay() {
      this.showNavbar = !this.showNavbar;
      this.showSidenav = !this.showSidenav;
      this.showFooter = !this.showFooter;
    },
    toggleHideConfig() {
      this.hideConfigButton = !this.hideConfigButton;
    },
    setMessage(newMessage) {
      this.message = newMessage;
    },
    clearMessage() {
      this.message = null;
    },
  },
});
