<template>
  <nav
      id="navbarBlur"
      class="shadow-none navbar navbar-main navbar-expand-lg border-radius-xl"
      :class="[isRTL ? 'top-1 position-sticky z-index-sticky' : '']"
      v-bind="$attrs"
      data-scroll="true"
      v-if="isLoggedIn"
  >
    <div class="px-3 py-1 container-fluid">
      <breadcrumbs
          :current-directory="currentDirectory"
          :current-page="currentRouteName"
          :text-white="textWhite"
      />
      <div
          class="sidenav-toggler sidenav-toggler-inner d-xl-block d-none"
          :class="isRTL ? 'me-3' : ''"
      >
        <a href="#" class="p-0 nav-link text-body" @click.prevent="minNav">
          <div class="sidenav-toggler-inner">
            <i
                class="sidenav-toggler-line"
                :class="textWhite ? 'bg-white' : ''"
            ></i>
            <i
                class="sidenav-toggler-line"
                :class="textWhite ? 'bg-white' : ''"
            ></i>
            <i
                class="sidenav-toggler-line"
                :class="textWhite ? 'bg-white' : ''"
            ></i>
          </div>
        </a>
      </div>
      <div
          id="navbar"
          class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4"
          :class="isRTL ? 'px-0' : 'me-sm-4'"
      >
        <div
            class="pe-md-3 d-flex align-items-center"
            :class="isRTL ? 'me-md-auto' : 'ms-md-auto'"
        >
        </div>
        <ul class="navbar-nav justify-content-end">
          <li class="nav-item ps-3 d-flex align-items-center">
            <span class="d-sm-inline d-none"> {{tenantName}} </span>
          </li>
          <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
            <a
                id="iconNavbarSidenav"
                href="#"
                class="p-0 nav-link text-body"
                @click="toggleSidebar"
            >
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
              </div>
            </a>
          </li>
          <li class="nav-item d-flex px-3  align-items-center">
            <router-link
              v-if="isLoggedIn"
                class="px-0 nav-link font-weight-bold"
                :class="textWhite ? textWhite : 'text-body'"
             to="/">
            <a
                class="px-0 nav-link font-weight-bold"
                :class="textWhite ? textWhite : 'text-body'"
              @click="logoutUser"
            >
              <i class="fa fa-user" :class="isRTL ? 'ms-sm-2' : 'me-sm-1'"></i>
              <span class="d-sm-inline d-none">Cerrar Sesión</span>
            </a>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { defineComponent, computed, ref, onMounted, watch } from 'vue';
import { useMainStore } from '@/store/index';
import { useAuthStore } from '@/store/auth/auth.module';
import { useRouter, useRoute } from 'vue-router';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import { useCookies } from 'vue3-cookies';

export default defineComponent({
  name: 'Navbar',
  components: {
    Breadcrumbs
  },
  props: {
    minNav: {
      type: Function,
      default: () => {},
    },
    textWhite: {
      type: String,
      default: '',
    },
  },
  setup() {
    const messages = ref([]);
    const tenantName = ref('');
    const store = useMainStore();
    const authStore = useAuthStore();
    const showMenu = ref(false);
    const router = useRouter();
    const route = useRoute();
    const { cookies } = useCookies();

    const currentRouteName = computed(() => route.name);
    const currentDirectory = route.path.split("/")[1].charAt(0).toUpperCase() + route.path.split("/")[1].slice(1);
    const isRTL = computed(() => store.isRTL);

    const toggleSidebar = () => {
      store.navbarMinimize();
    };

    const toggleMenu = () => {
      showMenu.value = !showMenu.value;
    };

    const isLoggedIn = computed(() => authStore.isLoggedIn);

    const logoutUser = async() => {
      try {
        await authStore.logout();

        if (authStore.tenantLogin?.status?.success) {
          cookies.remove("user");
          cookies.remove("token");
          cookies.remove("tenant_id");
          cookies.remove("app_tenant");
          router.push("/login");
        }

      } catch (error) {
        messages.value = [];
        const newMessage = {
          id: messages.value.length + 1,
          content: ` ${error.response?.statusText} - ${error.response?.status}`,
          severity: 'error',
          code: error.response?.status
        };
        messages.value.push(newMessage);
      }
    };

    const updateTenantName = () => {
      const tenant_id = cookies.get('tenant_id');
      const app_tenant = cookies.get('app_tenant');
      tenantName.value = app_tenant == 'central' ? 'Central' : tenant_id;
    };

    onMounted(() => {
      updateTenantName();
    });

    watch(route, () => {
      updateTenantName();
    });

    return {
      showMenu,
      currentRouteName,
      currentDirectory,
      isRTL,
      isLoggedIn,
      logoutUser,
      messages,
      toggleSidebar,
      toggleMenu,
      router,
      route,
      tenantName
    };
  },
});

</script>

<style>
a:hover {
  cursor: pointer;
}
</style>
