<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Administración de usuarios</h5>
              </div>
              <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <Button
                      label="+&nbsp;Nuevo"
                      class="mx-1 mb-0 btn bg-gradient-success btn-sm"
                      @click="navigateToUserAdd"
                      :disabled="!can('users_create')"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive mt-4">
              <GemMessage :messages="messages" ></GemMessage>
              <DataTable :value="usersStore.usersList" show-gridlines class="table table-flush" table-style="min-width: 50rem">
                <template #empty> No se encontraron resultados </template>
                <template #loading> Cargando resultados, por favor espere... </template>
                <Column field="cuil" header="Cuil" :style="{'padding-left': '10px'}"></Column>
                <Column field="name" header="Nombre" :style="{'padding-left': '10px'}"></Column>
                <Column field="last_name" header="Apellido" :style="{'padding-left': '10px'}"></Column>
                <Column field="email" header="Correo" :style="{'padding-left': '10px'}"></Column>
                <Column header="Perfiles">
                  <template #body="slotProps">
                    <span class="mt-3 me-1 badge badge-sm bg-info" v-for="rol in slotProps.data.roles" :key="rol.id">{{ rol.display_name }} </span>
                  </template>
                </Column>
                <Column field="main_tenant_admin" header="Habilitado">
                  <template #body="slotProps">
                    <ToggleButton
                        :model-value="Boolean(slotProps.data.is_active)"
                        data-bs-toggle="tooltip" title="Habilitar usuario"
                        on-icon="pi pi-check"
                        off-icon="pi pi-times"
                        on-label="Habilitado"
                        off-label="Deshabilitado"
                        @change="toggleUserActivation(slotProps.data)"
                        :disabled="!can('users_edit')"
                    />
                  </template>
                </Column>
                <Column header="Acciones" style="min-width:8rem">
                  <template #body="slotProps">
                      <Button
                          data-bs-toggle="tooltip" title="Ver usuario"
                          icon="pi pi-eye"
                          outlined
                          rounded
                          class="mr-2"
                          @click="goToDetails(slotProps.data.id)"
                          :disabled="!can('users_manage')"
                      />
                      <Button icon="pi pi-pencil" outlined rounded class="mr-2" data-bs-toggle="tooltip" title="Editar usuario" @click="editUser(slotProps.data.id)" :disabled="!can('users_edit')" />
                  </template>
                </Column>

              </DataTable>
              <Paginator
                  :template="{
                  default: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown',
                }"
                  :rows="10"
                  :total-records="usersStore.usersPaginator.total"
                  :rows-per-page-options="[10,25,50,100]"
                  @page="onPageChange"
              />

            </div>
            <Dialog v-model:visible="deleteUserDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
              <div class="confirmation-content">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                <span v-if="users.length">Está seguro que desea eliminar el usuario <b>{{ selectedUser }}</b>?</span>
              </div>
              <template #footer>
                <Button label="Sí" icon="pi pi-check" text @click="deleteUser" />
                <Button label="No" icon="pi pi-times" text @click="deleteUserDialog = false"/>
              </template>
            </Dialog>
            <Dialog 
              v-model:visible="activateUserDialog" 
              :style="{ width: '450px' }" 
              header="Confirmar" 
              modal>
              <div class="confirmation-content">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                <span v-if="selectedUser.is_active">Está seguro que desea <b>desactivar</b> el usuario <b>{{ selectedUser.name }}</b>?</span>
                <span v-else>Está seguro que desea <b>activar</b> el usuario <b>{{ selectedUser.name }}</b>?</span>
              </div>
              <template #footer>
                <Button label="Sí" icon="pi pi-check" text @click="activateUser" />
                <Button label="No" icon="pi pi-times" text @click="activateUserDialog = false" />
              </template>
            </Dialog>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useUserListStore } from '@/store/users/userslist.module';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import ToggleButton from 'primevue/togglebutton';
import Dialog from 'primevue/dialog';
import Tooltip from 'primevue/tooltip';
import { useAbility } from '@casl/vue';
import GemMessage from '/src/components/GemMessage.vue';
import { useMainStore } from '@/store/index';
import Paginator from 'primevue/paginator';

export default {
  name: 'ListUsers',
  directives: {
    'tooltip': Tooltip
  },
  components: {
    DataTable,
    Column,
    Button,
    ToggleButton,
    Dialog,
    GemMessage,
    Paginator,
  },
  setup() {
    const router = useRouter();
    const usersStore = useUserListStore();
    const selectedUser = ref('');
    const deleteUserDialog= ref(false);
    const first = ref(0);
    const { can } = useAbility();
    const activateUserDialog = ref(false);
    const mainStore = useMainStore();
    const messages = ref(mainStore.message ? [mainStore.message] : []);

    const fetchUsers = async (page = 1, rows = 10) => {
      try {
        await usersStore.fetchUsers(page, rows);
        mainStore.clearMessage();
      } catch (error) {
        messages.value = [];
        const newMessage = {
          id: messages.value.length + 1,
          content: ` ${ error.response?.statusText } - ${ error.response?.status }`,
          severity: 'error',
          code: error.response?.status,
        };
        messages.value.push(newMessage);
      }
    };

    const enableUser = async (id) => {
      try {
        await usersStore.enableUser(id);
        messages.value = [];
        const newMessage = {
          id: messages.value.length + 1,
          content: `El usuario fue habilitado correctamente`,
          severity: 'success',
          code: 200,
        };
        messages.value.push(newMessage);
      } catch (error) {
        messages.value = [];
        const errorMessages = error.response?.data?.status?.messages;
        errorMessages.forEach((errorMessage) => {
          const newMessage = {
            id: messages.value.length + 1,
            content: errorMessage,
            severity: 'error',
            code: error.response?.status,
          };
          messages.value.push(newMessage);
        });
      }
    };

    const disableUser = async (id) => {
      try {
        await usersStore.disableUser(id);
        messages.value = [];
        const newMessage = {
          id: messages.value.length + 1,
          content: `El usuario fue deshabilitado correctamente`,
          severity: 'success',
          code: 200,
        };
        messages.value.push(newMessage);

      } catch (error) {
        const errorMessages = error.response?.data?.status?.messages;

        messages.value = [];
        errorMessages.forEach((errorMessage) => {
          const newMessage = {
            id: messages.value.length + 1,
            content: errorMessage,
            severity: 'error',
            code: error.response?.status,
          };
          messages.value.push(newMessage);
        });

      }
    };

    const editUser = (userId) => {
      router.push({ path: '/user/edit', query: { userId } });
    };

    const onPageChange = (event) => {
      fetchUsers(event.page + 1, event.rows);
      first.value = event.first;
    };

    const toggleUserActivation = (data) => {
      selectedUser.value = data;
      activateUserDialog.value = true;
    };

    const activateUser = async () => {

      const userData = selectedUser.value;
      const id = userData.id;

      activateUserDialog.value = false;
      
      if (userData.is_active) {
        await disableUser(id);
      } else {
        await enableUser(id);
      }

    };

    const confirmDeleteUser = (data) => {
      selectedUser.value = data.cuil;
      deleteUserDialog.value = true;
    };

    const deleteUser = async () => {
      try {
        await usersStore.deleteUser(selectedUser.value); // Añade la lógica para eliminar el usuario
        deleteUserDialog.value = false;
      } catch (error) {
        messages.value = [];
        const newMessage = {
          id: messages.value.length + 1,
          content: ` ${error.message}`,
          severity: 'error',
        };
        messages.value.push(newMessage);
      }
    };

    const goToDetails = (userId) => {
      router.push({ path: '/user/detail', query: { userId } });
    };
    const navigateToUserAdd = () => {
      router.push({ path: '/user/add' });
    };

    onMounted(() => {
      fetchUsers();
    });

    return {
      usersStore,
      deleteUserDialog,
      selectedUser,
      messages,
      editUser,
      toggleUserActivation,
      activateUserDialog,
      activateUser,
      Dialog,
      confirmDeleteUser,
      deleteUser,
      onPageChange,
      first,
      navigateToUserAdd,
      goToDetails,
      can
    };
  },
};
</script>