<template>
<div>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          is-blur="blur blur-rounded my-3 py-2 start-0 end-0 mx-4 shadow"
          btn-background="bg-gradient-success"
          :dark-mode="true"
        />
      </div>
    </div>
  </div>
  <main class="mt-0 main-content main-content-bg">
    <section>
      <div class="page-header min-vh-75">
        <div class="container">
          <GemMessage ref="gemMessageRef" :messages="messages" ></GemMessage>
          <div>
            <div class="mx-auto card mt-6 px-3 col-xl-4 col-lg-5 col-md-6 d-flex flex-column">
              <div class=" my-7  card card-plain">
                <div class="pb-0 card-header text-start">
                  <h3 class="font-weight-bolder text-success text-gradient text-center">
                    Géminis
                  </h3>
                  <br />
                  <div class="text-center"></div>
                </div>
                <div class="card-body pb-3">
                  <Form
                    @submit="handleLogin"
                  >
                    <div>
                      <label for="cuil">Cuil</label>
                      <InputText
                        id="cuil"
                        v-model="user.cuil"
                        placeholder="Cuil"
                        name="cuil"
                        style="width: 100%"
                      />
                      <GemMessage :messages="msgErrorCuil"></GemMessage>
                      
                    </div>

                    <div>
                      <label>Contraseña</label>
                      <Password
                        v-model="user.password"
                        placeholder="Contraseña"
                        name="password"
                        style="width: 100%"
                        toggle-mask
                        :feedback="false"
                      />
                      <GemMessage :messages="msgErrorPass"></GemMessage>
                    </div>

                    <div class="text-center">
                      <Button
                        class="mb-0 btn bg-gradient-success float-end my-4 mb-2 round-button"
                        severity="contrast"
                        label="Iniciar sesión"
                        :disabled="loading"
                        type="submit"
                      >
                        <template v-if="loading">
                          <span class="spinner-border spinner-border-sm"></span>
                        </template>
                      </Button>
                    </div>
                  </Form>
                </div>
                <!-- ******************************************** -->
                <!-- Se deja comentado para las próximos tickets  -->
                <!-- ******************************************** -->

                <!-- <div class="px-1 pt-0 pb-3 text-center card-footer px-lg-2">
                  <p class="mx-auto mb-0 text-sm">
                    ¿No tienes una cuenta?
                    <router-link
                      :to="{ name: 'Register' }"
                      class="text-success text-gradient font-weight-bold"
                      >Inscribirse</router-link
                    >
                  </p>
                </div>
                <div class="px-1 pt-0 pb-3 text-center card-footer px-lg-2">
                  <p class="mx-auto mb-4 text-sm">
                    ¿Olvidaste tu contraseña?
                  </p> 
                </div>-->
                <!-- ******************************************** -->
                <!-- ******************************************** -->
                <!-- ******************************************** -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import Navbar from "@/components/PageLayout/Navbar.vue";
import InputText from 'primevue/inputtext';
import Password from 'primevue/password';
import Button from 'primevue/button';
import GemMessage from "/src/components/GemMessage.vue";
import { useAuthStore } from "@/store/auth/auth.module";
import { useMainStore } from '@/store/index';
import { Form } from "vee-validate";

export default {
  name: "Login",
  components: {
    Navbar,
    InputText,
    Password,
    Button,
    Form,
    GemMessage
  },
  setup() {
    const mainStore = useMainStore();
    const messages = ref([]);
    const msgErrorCuil = ref([]);
    const msgErrorPass = ref([]);
    const loading = ref(false);
    const storeAuth = useAuthStore();
    const router = useRouter();
    const body = document.getElementsByTagName("body")[0];
    const gemMessageRef = ref(null);

    const user = ref({
      cuil: "",
      password: "",
    });

    const errors = ref({
      cuil: null,
      password: null,
    });

    const validateForm = () => {

      let valid = true;
      let newMessage = [];
      msgErrorCuil.value = [];
      msgErrorPass.value = [];
      errors.value = {
        cuil: null,
        password: null,
      };



      if (!user.value.cuil) {
        errors.value.cuil = 'CUIL es requerido';
        newMessage = {
          id: msgErrorCuil.value.length + 1,
          content: errors.value.cuil,
          severity: 'error',
          code: 1
        };
        valid = false;
        msgErrorCuil.value.push(newMessage);
      } else if (!/^\d{11}$/.test(user.value.cuil)) {
        errors.value.cuil = 'Debe ser un Cuil válido ex.: 20960531354';
        newMessage = {
          id: msgErrorCuil.value.length + 1,
          content: errors.value.cuil,
          severity: 'error',
          code: 1
        };
        valid = false;
        msgErrorCuil.value.push(newMessage);
      }
      

      if (!user.value.password) {
        errors.value.password = 'Contraseña es requerida';
        newMessage = {
          id: msgErrorPass.value.length + 1,
          content: errors.value.password,
          severity: 'error',
          code: 1
        };
        valid = false;
        msgErrorPass.value.push(newMessage);
      }
      return valid;
    };
    

    const handleLogin = async() => {
      if (!validateForm()) return;
      try {
        loading.value = true;
        await storeAuth.login(user.value);
        if (storeAuth.tenantLogin?.status?.success) {
          if (storeAuth.tenantLogin?.data?.access_token) {
            storeAuth.loggedIn = true;
            router.push("/home");
          } else {
            if (storeAuth.tenantLogin.data.items.length > 1) {
              router.push("tenant/login");
            } 
          }
        } else if (storeAuth.tenantLogin?.access_token) {
          storeAuth.loggedIn = true;
          router.push("/home");
        }
      } catch (error) {

          if (gemMessageRef.value) {
            gemMessageRef.value.processMessages(error);
          }

          loading.value = false;
        }
    };

    return {
      loading,
      messages,
      msgErrorCuil,
      msgErrorPass,
      body,
      user,
      validateForm,
      mainStore,
      errors,
      handleLogin,
      gemMessageRef
    };
  },
  created() {
    this.mainStore.toggleEveryDisplay();
    this.mainStore.toggleHideConfig();
    const body = document.getElementsByTagName("body")[0];
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.mainStore.toggleEveryDisplay();
    this.mainStore.toggleHideConfig();
    const body = document.getElementsByTagName("body")[0];
    body.classList.add("bg-gray-100");
  },
};
</script>
<style scoped>
  .round-button {
    width: 100%;
  }
</style>
