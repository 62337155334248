<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Registrar usuario</h5>
              </div>
              <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <Button
                    label="Volver al listado"
                    class="mx-1 mb-0 btn bg-gradient-success btn-sm" 
                    @click="backListButtonClick" 
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <GemMessage :messages="messages"></GemMessage>
            <Form
                role="form"
                class="text-start"
                :validation-schema="schema"
                @submit="validateAndShowDialog"
            >
              <label class="form-label mt-2 row mt-4">Cuil</label>
              <Field v-model="user.cuil" name="cuil" type="text">
                <SoftModelInput
                    id="cuil"
                    v-model="user.cuil"
                    name="cuil"
                    type="text"
                    placeholder="CUIT/CUIL"
                />
                <ErrorMessage name="cuil" class="text-danger" />
              </Field>

              <label class="form-label mt-2 row mt-4">Nombre</label>
              <Field v-model="user.name" name="name" type="text">
                <SoftModelInput
                    id="name"
                    v-model="user.name"
                    name="name"
                    type="text"
                    placeholder="Nombre"
                />
                <ErrorMessage name="name" class="text-danger" />
              </Field>

              <label class="form-label mt-2 row mt-4">Apellido</label>
              <Field v-model="user.last_name" name="last_name" type="text">
                <SoftModelInput
                    id="last_name"
                    v-model="user.last_name"
                    name="last_name"
                    type="text"
                    placeholder="Apellido"
                />
                <ErrorMessage name="name" class="text-danger" />
              </Field>

              <label class="form-label mt-2 row mt-4">Email</label>
              <Field v-model="user.email" name="email" type="email">
                <SoftModelInput
                    id="email"
                    v-model="user.email"
                    name="email"
                    type="email"
                    placeholder="Email"
                />
                <ErrorMessage name="email" class="text-danger" />
              </Field>

              <label class="form-label mt-2 row mt-4">Contraseña</label>
              <Field v-model="user.password" name="password" type="password">
                <div class="input-group">
                  <input
                      v-model="user.password"
                      :type="showPassword ? 'text' : 'password'"
                      name="password"
                      class="form-control form-control-lg"
                      placeholder="Contraseña"
                  />
                  <span class="input-group-text" style="cursor: pointer;" @click="togglePassword">
                    <i :class="showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                  </span>
                </div>
                <ErrorMessage name="password" class="text-danger" />
              </Field>

              <!-- Nueva sección de selección de perfiles -->
              <div class="row">
                <div class="col-md-12">
                  <h5 class="mt-4 row mx-1">Asignar nuevos perfiles</h5>
                  <DataTable
                      v-model:selection="selectedRoles"
                      :value="filteredRoles"
                      data-key="id"
                      show-gridlines
                      class="table table-flush mt-3"
                      table-style="min-width: 50rem"
                      selection-mode="multiple"
                      scrollable
                      scroll-height="300px"
                  >
                    <Column selection-mode="multiple" header-style="width: 3em" :style="{'padding-left': '10px'}"></Column>
                    <Column field="display_name" header="Perfiles" :style="{'padding-left': '10px'}"></Column>
                    <Column field="description" header="Descripción" :style="{'padding-left': '10px'}"></Column>
                  </DataTable>
                  <Button
                      class="float-end mt-4 mb-1 mr-1 btn btn-gradient-black"
                      severity="contrast"
                      label="Asignar"
                      @click="assignRoles"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <h5 class="mt-4 row mx-1">Perfiles asignados</h5>
                  <DataTable
                      :value="assignedRoles"
                      data-key="id"
                      show-gridlines
                      class="table table-flush mt-3"
                      table-style="min-width: 50rem"
                      scrollable
                      scroll-height="300px"
                  >
                    <Column field="display_name" header="Perfiles" :style="{'padding-left': '10px'}"></Column>
                    <Column field="description" header="Descripción" :style="{'padding-left': '10px'}"></Column>
                    <Column header="Acción" style="min-width:8rem" :style="{'padding-left': '10px'}">
                      <template #body="slotProps">
                        <Button icon="pi pi-trash" outlined rounded severity="danger" @click="removeAssignedRole(slotProps.data.id)" />
                      </template>
                    </Column>
                  </DataTable>
                  <p v-if="errorMessage" class="text-danger">{{ errorMessage }}</p>
                </div>
              </div>
              <Button
                class="float-end mt-4 mb-1 mr-1 btn btn-gradient-black"
                severity="contrast"
                label="Cancelar"
                :disabled="loading"
                type="button"
                @click="cancelSave"
              >
                <template v-if="loading">
                  <span class="spinner-border spinner-border-sm"></span>
                </template>
              </Button>
              <Button
                class="float-end mt-4 mb-0 mr-1 btn bg-gradient-success"
                severity="contrast"
                label="Guardar"
                :disabled="loading"
                type="submit"
              >
                <template v-if="loading">
                  <span class="spinner-border spinner-border-sm"></span>
                </template>
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </div>
    <!-- Diálogo de Confirmación -->
    <Dialog v-model:visible="confirmDialogVisible" :style="{ width: '450px' }" header="Confirmar" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3 mb-2" style="font-size: 2.5rem" />
        <span>¿Está seguro que desea agregar al usuario <b>{{ user.name }} {{user.last_name}}</b>?</span>
      </div>
      <template #footer>
        <Button label="Sí" icon="pi pi-check" text @click="handleRegister" />
        <Button label="No" icon="pi pi-times" text @click="confirmDialogVisible = false" />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useUserStore } from '@/store/users/usersadd.module';
import SoftModelInput from '/src/components/SoftModelInput.vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import GemMessage from "/src/components/GemMessage.vue";
import { useMainStore } from '@/store/index';

export default defineComponent({
  name: 'UserAdd',
  components: {
    SoftModelInput,
    Form,
    Field,
    ErrorMessage,
    GemMessage,
    DataTable,
    Column,
    Button,
    Dialog
  },
  setup() {
    const userStore = useUserStore();
    const showPassword = ref(false);
    const errorMessage = ref('');
    const schema = yup.object().shape({
      cuil: yup.string().matches(/^(20|23|24|27)\d{8}\d$/, 'Verifique el formato del cuit/cuil').required('Ingrese un cuil'),
      name: yup.string().required('Ingrese el nombre completo'),
      last_name: yup.string().required('Ingrese el apellido'),
      email: yup.string().email('Ingrese un email válido').required('Ingrese el email'),
      password: yup
          .string()
          .min(8, 'El password debe tener al menos 8 caracteres')
          .matches(/[A-Z]/, 'El password debe contener al menos una letra mayúscula')
          .matches(/[a-z]/, 'El password debe contener al menos una letra minúscula')
          .matches(/\d/, 'El password debe contener al menos un número')
          .matches(/[\W_]/, 'El password debe contener al menos un carácter especial')
          .required('Ingrese el password'),
    });

    const user = ref({
      cuil: '',
      name: '',
      last_name: '',
      email: '',
      password: '',
      role: ''
    });
    const loading = ref(false);
    const messages = ref([]);
    const allRoles = ref([]);
    const selectedRoles = ref([]);
    const assignedRoles = ref([]);
    const confirmDialogVisible = ref(false);
    const router = useRouter();
    const mainStore = useMainStore();

    const filteredRoles = computed(() => {
      return allRoles.value.filter(role =>
          !assignedRoles.value.some(assignedRole => assignedRole.id === role.id)
      );
    });

    const fetchRoles = async () => {
      try {
          await userStore.getAllRolesAdd();
          allRoles.value = userStore.allRoles;

      } catch (error) {
        messages.value = [];
        if (error.response?.data || error.response?.data?.success === false) {
          const errorMessage = error.response?.data?.status?.messages || error.response?.statusText;
          messages.value.push({
            id: messages.value.length + 1,
            content: `${errorMessage}`,
            severity: 'error',
            code: error.response?.status
          });
        } else {
          messages.value.push({
            id: messages.value.length + 1,
            content: ` ${error.response?.statusText} - ${error.response?.status}`,
            severity: 'error',
            code: error.response?.status
          });
        }
      }
    };

    const assignRoles = () => {
      errorMessage.value = '';
      const newRoles = selectedRoles.value.filter(role => !assignedRoles.value.some(r => r.id === role.id));
      assignedRoles.value = [...assignedRoles.value, ...newRoles];
    };

    const removeAssignedRole = (roleId) => {
      assignedRoles.value = assignedRoles.value.filter(role => role.id !== roleId);
      selectedRoles.value = selectedRoles.value.filter(role => role.id !== roleId);
    };

    const cancelSave = () => {
      router.push('/user/list');
    };

    const backListButtonClick = () => {
      router.push('/user/list');
    };

    const validateAndShowDialog = async () => {
      if (assignedRoles.value.length === 0) {
        errorMessage.value = 'Debe asignar al menos un perfil al usuario antes de guardar.';
        return;
      }
      confirmDialogVisible.value = true;
    };

    const handleRegister = async () => {
      confirmDialogVisible.value = false;
      try {
        loading.value = true;
        const userToRegister = {
          ...user.value,
          roles: assignedRoles.value.map(role => role.name),
        };

        await userStore.addUser(userToRegister);
        messages.value = [];
        const newMessage = {
          id: messages.value.length + 1,
          content: `El usuario con nombre: ${userToRegister.name} ${userToRegister.last_name} se ha registrado exitosamente`,
          severity: 'success',
          code: 1
        };
        mainStore.setMessage(newMessage);
        await router.push('/user/list');
      } catch (error) {
        messages.value = [];
        if (error.response?.data || error.response?.data?.success === false) {
          const errorMessage = error.response?.data?.status?.messages || error.response?.statusText;
          messages.value.push({
            id: messages.value.length + 1,
            content: `${errorMessage}`,
            severity: 'error',
            code: error.response?.status
          });
        } else {
          messages.value.push({
            id: messages.value.length + 1,
            content: ` ${error.response?.statusText} - ${error.response?.status}`,
            severity: 'error',
            code: error.response?.status
          });
        }
        loading.value = false;
      }
    };

    const togglePassword = () => {
      showPassword.value = !showPassword.value;
    };

    onMounted(async () => {
      try {
        await fetchRoles();
      } catch (error) {
        console.error("Error in mounted hook:", error);
      }

    });

    return {
      schema,
      user,
      loading,
      handleRegister,
      backListButtonClick,
      validateAndShowDialog,
      confirmDialogVisible,
      showPassword,
      togglePassword,
      filteredRoles,
      cancelSave,
      selectedRoles,
      assignedRoles,
      assignRoles,
      removeAssignedRole,
      errorMessage,
      messages,
      mainStore,
    };
  }
});
</script>
<style scoped>
  .confirmation-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
</style>
