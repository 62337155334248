import { defineStore } from 'pinia';
import profileDetailService from '../../services/profiles/profiledetail.service';

export const useProfileDetailStore = defineStore({
  id: 'profiledetail',
  state: () => ({
    moduleprofiledetail: [],
    moduleprofiledetailpaginator: [],
  }),
  getters: {
    profiledetails: (state) => state.profiledetail,
  },
  actions: {
    async fetchProfileModuleById(id, page, rows) {
        const response = await profileDetailService.getProfileDetailModulesById(id, page, rows);
        this.moduleprofiledetail = response.data.data;
        this.moduleprofiledetailpaginator = response.data.data.pagination;

    },
  }
});
