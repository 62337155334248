<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Administración de perfiles</h5>
              </div>
              <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <Button
                      label="+&nbsp;Nuevo"
                      class="mx-1 mb-0 btn bg-gradient-success btn-sm"
                      @click="navigateToProfileAdd"
                      :disabled="!can('profile_create')"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive mt-4">
              <GemMessage :messages="messages" ></GemMessage>
              <DataTable :value="storeRoles.rolepermission" showGridlines class="table table-flush" tableStyle="min-width: 50rem">
                <Column field="id" header="id" hidden ></Column>
                <Column field="display_name" header="Perfil"></Column>
                <Column field="description" header="Descripción"></Column>
                <Column header="Acciones" style="min-width:8rem">
                  <template #body="slotProps">
                      <Button icon="pi pi-eye" outlined rounded class="mr-2" data-bs-toggle="tooltip" title="Ver Perfil" @click="$router.push({ path: '/profile/detail', query: { roleId: slotProps.data.id } })"  :disabled="!can('profile_manage')"  />
                      <Button icon="pi pi-pencil" outlined rounded class="mr-2" data-bs-toggle="tooltip" title="Editar Perfil" @click="$router.push({ path: '/profile/edit', query: { roleId: slotProps.data.id } })" :disabled="!can('profile_edit')"  />
                      <Button icon="pi pi-trash" outlined rounded severity="danger" data-bs-toggle="tooltip" title="Eliminar Perfil" @click="confirmDeleteProfile(slotProps.data)" :disabled="!can('profile_delete')"  />
                  </template>
                </Column>
                <Dialog v-model:visible="deleteProfileDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
                  <div class="confirmation-content">
                    <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                    <span v-if="selectedProfile">Está seguro que desea eliminar el perfil <b>{{ selectedProfile.display_name }}</b>?</span>
                  </div>
                  <template #footer>
                    <Button label="Sí" icon="pi pi-check" text @click="deleteProfile" />
                    <Button label="No" icon="pi pi-times" text @click="deleteProfileDialog = false"/>
                  </template>
                </Dialog>
              </DataTable>
              <Paginator
                  :rows="10"
                  :totalRecords="storeRoles.rolepermissionPaginator?.total"
                  :rowsPerPageOptions="[ 10, 25, 50, 100]"
                  @page="onPageChange"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useRolePermissionStore } from "@/store/rolepermissions/rolepermission.module";
import { useMainStore } from '@/store/index';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Paginator from 'primevue/paginator';
import { useAbility } from '@casl/vue';
import GemMessage from "/src/components/GemMessage.vue";

export default {
  name: "ProfileManagement",
  components: {
    DataTable,
    Column,
    Button,
    Dialog,
    Paginator,
    GemMessage,
  },
  setup() {
    const mainStore = useMainStore();
    const storeRoles = useRolePermissionStore();
    const router = useRouter();
    const deleteProfileDialog = ref(false);
    const selectedProfile = ref(null);
    const { can } = useAbility();
    const messages = ref(mainStore.message ? [mainStore.message] : []);
    const first = ref(0);

    const fetchData = async (page = 1, rows = 10) => {
      try {
        await storeRoles.fetchRolePermissions(page, rows);
        mainStore.clearMessage();
      } catch (error) {
        messages.value = [];
        const newMessage = {
          id: messages.value.length + 1,
          content: `${error.response?.statusText} - ${error.response?.status}`,
          severity: 'error',
          code: error.response?.status,
        };
        messages.value.push(newMessage);
      }
    };

    const onPageChange = (event) => {
      fetchData(event.page + 1, event.rows);
      first.value = event.first;
    };

    const navigateToProfileAdd = () => {
      router.push({ name: "ProfileAdd" });
    };

    const confirmDeleteProfile = (data) => {
      selectedProfile.value = data;
      deleteProfileDialog.value = true;
    };

    const deleteProfile = async () => {
      try {
        await storeRoles.deleterolepermission(selectedProfile.value.id);
        messages.value = [];
        messages.value.push({
          id: messages.value.length + 1,
          content: `Perfil con nombre: ${selectedProfile.value.name}, eliminado con éxito.`,
          severity: 'success',
        });
        deleteProfileDialog.value = false;
        fetchData(
          storeRoles.rolepermissionPaginator.current_page,
          storeRoles.rolepermissionPaginator.per_page
        );
      } catch (error) {
        messages.value = [];
        deleteProfileDialog.value = false;
        if (error.response?.data && error.response?.data?.status?.success === false) {
          messages.value.push({
            id: messages.value.length + 1,
            content: error.response?.data?.status?.messages[0],
            severity: 'error',
            code: 99,
          });
        } else {
          messages.value.push({
            id: messages.value.length + 1,
            content: `${error.response?.statusText} - ${error.response?.status}`,
            severity: 'error',
            code: error.response?.status,
          });
        }
        fetchData(this.currentPage, this.rowsPerPage);
      }
    };


    return {
      deleteProfileDialog,
      selectedProfile,
      storeRoles,
      messages,
      first,
      fetchData,
      onPageChange,
      navigateToProfileAdd,
      confirmDeleteProfile,
      deleteProfile,
      can
    };
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style scoped>
.confirmation-content {
  display: flex;
  align-items: center;
}

</style>
