<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Editar perfil</h5>
              </div>
              <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <Button
                    label="Volver al listado"
                    class="mx-1 mb-0 btn bg-gradient-success btn-sm" 
                    @click="backListButtonClick" 
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div v-if="loadingData">
              <p>Cargando...</p>
            </div>
            <div v-else>
            <GemMessage :messages="messages" ></GemMessage>  
            <Form
                role="form"
                class="text-start"
                @submit="confirmSave"
            >
              <div class="row">
                <div class="col-md-6">
                  <label class="form-label mt-2 row mt-4">Nombre del perfil</label>
                  <InputText v-model="profile[0].roles.display_name" disabled placeholder="Disabled" style="width: 100%"/>
                  <div v-if="errors.name" class="text-danger">{{ errors.name }}</div>
                </div>

                <div class="col-md-6">
                  <label class="form-label mt-2 row mt-4">Descripción</label>
                  <InputText v-model="profile[0].roles.description" disabled placeholder="Disabled" style="width: 100%"/>
                  <div v-if="errors.description" class="text-danger">{{ errors.description }}</div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <label class="form-label mt-2 row mt-4">Submódulo</label>
                  <Dropdown 
                    v-model="selectedSubModules" 
                    :options="submodules" 
                    option-label="name" 
                    placeholder="Seleccione un submódulo" 
                    class="w-full md:w-56"
                  />
                  <div v-if="errors.module" class="text-danger">{{ errors.module }}</div>
                </div>
                <div>
                </div>
              </div>

              <h5 class="mt-4">Asignar permisos</h5>
              <div class="row">
                <div class="col-md-12">
                  <div class="col-12">
                    
                    <DataTable 
                        v-model:selection="selectPermissions" 
                        :value="permissions" 
                        data-key="id"
                        show-gridlines 
                        class="table table-flush" 
                        table-style="min-width: 50rem"
                        selection-mode="multiple"
                    >
                        <Column selection-mode="multiple" header-style="width: 3em"></Column>
                        <Column field="id" header="ID" hidden></Column>
                        <Column field="name" header="Módulo"></Column>
                        <Column field="description" header="Submódulos"></Column>
                    </DataTable>
                    <Button
                        class="float-end mt-4 mb-0 btn btn-gradient-black"
                        severity="contrast"
                        label="Asignar"
                        :disabled="loading"
                        @click="assignPermissions"
                    >
                      <template v-if="loading">
                        <span class="spinner-border spinner-border-sm"></span>
                      </template>
                    </Button>
                  </div>
                </div>
              </div>

              <h5 class="mt-4">Permisos asignados</h5>
              <div class="row">
                <div class="col-12">
                  <DataTable 
                    :value="assigningPermissions" 
                    show-gridlines 
                    class="table table-flush" 
                    table-style="min-width: 50rem">
                    <Column field="id" header="ID" hidden></Column>
                    <Column field="name" header="Módulo"></Column>
                    <Column field="description" header="Submódulos"></Column>
                    <Column field="permissionName" header="Permiso"></Column>
                    <Column field="permissionDescription" header="Funcionalidad"></Column>
                    <Column header="Acción" style="min-width:8rem">
                      <template #body="slotProps">
                        <Button icon="pi pi-trash" outlined rounded severity="danger" @click="removePermission(slotProps.data.permissionName)" />
                      </template>
                    </Column>
                  </DataTable>
                  <Paginator
                    :template="{
                      default: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown',
                    }"
                    :first="first"
                    :rows="10"
                    :total-records="pagination.total"
                    :rows-per-page-options="[10,25,50,100]"
                    @page="onPageChange"
                  />
                  <div>
                    <Button
                      class="float-end mt-4 mb-0 mr-1 btn btn-gradient-black"
                      severity="contrast"
                      label="Cancelar"
                      :disabled="loading"
                      type="button"
                      @click="cancelSavePermission"
                    >
                      <template v-if="loading">
                        <span class="spinner-border spinner-border-sm"></span>
                      </template>
                    </Button>
                    <Button
                      class="float-end mt-4 mb-0 mr-1 btn bg-gradient-success"
                      severity="contrast"
                      label="Guardar"
                      :disabled="loading"
                      type="submit"
                    >
                      <template v-if="loading">
                        <span class="spinner-border spinner-border-sm"></span>
                      </template>
                    </Button>

                    
                  </div>
                </div>
              </div>
              
            </Form>
          </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Dialog de confirmación -->
    <Dialog v-model:visible="createProfileDialog" :style="{ width: '450px' }" header="Confirmar" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="profile[0].roles.display_name">¿Está seguro que desea guardar el perfil <b>{{ profile[0].roles.display_name }}</b>?</span>
      </div>
      <template #footer>
        <Button label="Sí" icon="pi pi-check" text @click="handleRegister" />
        <Button label="No" icon="pi pi-times" text @click="createProfileDialog = false" />
      </template>
    </Dialog>
  </div>
  
</template>

<script>
import { ref, watch, onUnmounted } from 'vue';
import { useRouter } from 'vue-router';
import { useProfileEditStore } from "@/store/profiles/profileedit.module";
import { useModuleStore } from "@/store/modules/module.module";
import DataTable from 'primevue/datatable';
import Dialog from 'primevue/dialog';
import Column from 'primevue/column';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import { Form } from 'vee-validate';
import GemMessage from "/src/components/GemMessage.vue";
import * as yup from "yup";
import Paginator from 'primevue/paginator';

export default {
  name: "ProfileEdit",
  components: {
    Form,
    DataTable,
    Column,
    Button,
    Dialog,
    GemMessage,
    InputText,
    Dropdown,
    Paginator,
  },
  setup() {
    const first = ref(0);
    const profile = ref([]);
    const messages = ref([]);
    const pagination = ref([]);
    const router = useRouter();
    const loading = ref(false);
    const expandedRows = ref([]);
    const cleanedData = ref([]);
    const loadingData = ref(true);
    const selectPermissions = ref([]);
    const assignedPermissions = ref([]);
    const createProfileDialog = ref(false);
    const storeProfileEdit = useProfileEditStore();
    const storeModules = useModuleStore();
    const selectedSubModules = ref('');
    const permissionToRemove = ref('');
    
    const schema = yup.object().shape({
      profile_name: yup.string().matches(/^[a-zA-ZáéíóúÁÉÍÓÚÜü\s0-9]*$/, "El nombre ingresado no es válido").required("Ingrese el Nombre del Perfil"),
      description: yup.string().matches(/^[a-zA-ZáéíóúÁÉÍÓÚÜü\s0-9]*$/, "La descripción ingresada no es válida").required("Ingrese la Descripción"),
    });

    const errors = ref({
      name: null,
      description: null,
      module: null,
    });

    const fetchProfileEdit = async (page = 1, rows = 10) => {
      const urlParams = new URLSearchParams(window.location.search);
      const roleId = urlParams.get('roleId');
      
      if (roleId) {
        try {
          await storeProfileEdit.fetchRoleModuleById(roleId, page, rows);
          profile.value = storeProfileEdit.modulerolepermission.data;
          pagination.value = storeProfileEdit.modulerolePaginator;
        } catch (error) {
          messages.value = [];
          const newMessage = {
            id: messages.value.length + 1,
            content: ` ${error.response?.statusText} - ${error.response?.status}`,
            severity: 'error',
            code: error.response?.status
          };
          messages.value.push(newMessage);
        } finally {
          loadingData.value = false;
        }
      } else {
        messages.value = [];
        const newMessage = {
          id: messages.value.length + 1,
          content: `Falta el nombre de la entidad en la URL`,
          severity: 'error',
        };
        messages.value.push(newMessage);
        loadingData.value = false;
      }
    };

    const onPageChange = async (event) => {
      const page = event.page + 1;
      const rows = event.rows;
      await fetchProfileEdit(page, rows);
    };

    const fetchProfileSubModules = async (page = 1, rows = 0) => {
      try {
        await storeModules.fetchSubModules(page, rows);
      } catch (error) {
        messages.value = [];
        const newMessage = {
          id: messages.value.length + 1,
          content: ` ${error.response?.statusText} - ${error.response?.status}`,
          severity: 'error',
          code: error.response?.status
        };
        messages.value.push(newMessage);
      }
    };

    const fetchPermissionsByModule = async (id, page = 1, rows=0) => {
      try {
        await storeProfileEdit.fetchPermissionsByModule(id, page, rows);
      } catch (error) {
        messages.value = [];
        const newMessage = {
          id: messages.value.length + 1,
          content: ` ${error.response?.statusText} - ${error.response?.status}`,
          severity: 'error',
          code: error.response?.status
        };
        messages.value.push(newMessage);
      }
    };


    const assignPermissions = () => {

      const newPermissions = selectPermissions.value.filter(permission => {
        return !assignedPermissions.value.some(assigned => 
          assigned.permissions.some(assignedPermission => 
            assignedPermission.name === permission.name
          )
        );
      });

      if (newPermissions.length > 0) {
        assignedPermissions.value = [
          ...assignedPermissions.value,
          ...newPermissions.map(permission => ({
            id: selectedSubModules.value.id,
            name: selectedSubModules.value.name,
            description: selectedSubModules.value.description,
            parent_id: selectedSubModules.value.parent_id,
            permissions: [{
                id: permission.id,
                name: permission.name,
                guard_name: permission.guard_name,
                description: permission.description,
                module_id: permission.module_id,
                created_at: permission.created_at,
                updated_at: permission.updated_at,
              }
            ],
            roles: {
              id: profile.value[0].roles.id,
              name: profile.value[0].roles.name,
              description: profile.value[0].roles.description,
            }
          }))
        ];
      }


      selectPermissions.value = [];
};
    
    const handleRegister = async () => {
      try {
        loading.value = true;
        
        if (!profile.value[0].permissions || profile.value[0].permissions.length === 0) {
          messages.value = [];
          const newMessage = {
            id: messages.value.length + 1,
            content: ` No tiene permisos asignados para guardar `,
            severity: 'success',
            code: 1
          };
          messages.value.push(newMessage);
          loading.value = false;
          return;
        }

        if (permissionToRemove.value.length > 1) {
          confirmRemovePermission();
          permissionToRemove.value = '';
        }

        await storeProfileEdit.assignPermissionToRole(assignedPermissions.value);

        messages.value = [];
        const newMessage = {
          id: messages.value.length + 1,
          content: ` El perfil se ha editado exitosamente `,
          severity: 'success',
          code: 1
        };
        messages.value.push(newMessage);
        loading.value = false;
        createProfileDialog.value = false;
      } catch (error) {
        if (error.response?.data || error.response?.data?.success === false) {
          if (error.response?.data?.errors) {
            error.response.data.errors.forEach(err => {
              messages.value = [];
              const newMessage = {
                id: messages.value.length + 1,
                content: err.message,
                severity: 'error',
                code: 5
              };
              messages.value.push(newMessage);
            });
            loading.value = false;
          } else {
            messages.value = [];
            const newMessage = {
              id: messages.value.length + 1,
              content: ` Error de conexión al servidor `,
              severity: 'error',
              code: 5
            };
            messages.value.push(newMessage);
            loading.value = false;
          }
        } else {
          messages.value = [];
          const newMessage = {
            id: messages.value.length + 1,
            content: ` ${ error.response?.statusText } - ${ error.response?.status }`,
            severity: 'error',
            code: error.response?.status
          };
          messages.value.push(newMessage);
        }
        createProfileDialog.value = false;
        loading.value = false;
      }
    };

    const cleanData = (data) => {
      return data.value.map(module => {
        return {
          description: module.description,
          id: module.id,
          name: module.name,
          parent_id: module.parent_id,
          permissions: module.permissions.map(permission => {
            return {
              name: permission.name,
              description: permission.description,
              module_id: permission.module_id
            };
          }),
          roles: {
            description: module.roles.description,
            display_name: module.roles.display_name,
            guard_name: module.roles.guard_name,
            id: module.roles.id,
            name: module.roles.name
          }
        };
      });
    }

    const confirmRemovePermission = async() => {
      
      try {
        const clearData = cleanData(assignedPermissions);
        
        await storeProfileEdit.removePermissionToRole(clearData);
        const newMessage = {
          id: messages.value.length + 1,
          content: ` El permiso se ha sido eliminado `,
          severity: 'success',
          code: 1
        };
        messages.value.push(newMessage);
        loading.value = false;
      } catch (error) {
        const newMessage = {
          id: messages.value.length + 1,
          content: ` ${error.response?.statusText} - ${error.response?.status}`,
          severity: 'error',
          code: error.response?.status
        };
        messages.value.push(newMessage);
      }
    };

    const cancelSavePermission = () => {
      router.push('/profile/list');
    };

    const backListButtonClick = () => {
      router.push('/profile/list');
    }

    onUnmounted(() => {
      storeProfileEdit.modulepermission = [];
    });

    const removePermission = (permissionName) => {
      let removedPermissions = [];
      assignedPermissions.value = assignedPermissions.value.map(module => {
        if (module.permissions && Array.isArray(module.permissions)) {
          const remainingPermissions = module.permissions.filter(permission => {
            const shouldRemove = permission.name !== permissionName;
            if (!shouldRemove) {
              removedPermissions.push(permission);
            }
            return shouldRemove;
          });

          module.permissions = remainingPermissions;
        }
        return module;
      });
      permissionToRemove.value = permissionName;
    };

    const confirmSave = () => {
      createProfileDialog.value = true;
    }


    watch(selectedSubModules, (module) => {
      if (module.id) {
        fetchPermissionsByModule(module.id)
      }
    });
    
    // Asignar permisos seleccionados al montar el componente
    watch(
      () => profile.value,
      (profileValue) => {
        assignedPermissions.value = profileValue || [];
      },
      { immediate: true }
    );
    return {
      schema,
      storeProfileEdit,
      storeModules,
      selectPermissions,
      selectedSubModules,
      fetchProfileEdit,
      fetchProfileSubModules,
      fetchPermissionsByModule,
      messages,
      loading,
      loadingData,
      first,
      errors,
      cleanedData,
      handleRegister,
      expandedRows,
      createProfileDialog,
      assignedPermissions,
      assignPermissions,
      backListButtonClick,
      confirmRemovePermission,
      cancelSavePermission,
      removePermission,
      onPageChange,
      profile,
      pagination,
      confirmSave,
    };
  },
  
  computed: {
    assigningPermissions() {
      return this.assignedPermissions.flatMap(permission => 
        permission.permissions.map(p => ({
          id: permission.id,
          name: permission.name,
          description: permission.description,
          permissionDescription: p.description,
          permissionName: p.name
        }))
      );
    },
    submodules() {
      return this.storeModules.modules || [];
    },
    permissions() {
      return this.storeProfileEdit.modulepermission || [];
    },
  },
  mounted() {
    this.fetchProfileEdit();
    this.fetchProfileSubModules();
  },
};
</script>
