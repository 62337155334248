<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Administración de municipios</h5>
              </div>
              <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <Button
                    type="button"
                    label="Volver al listado"
                    class="mx-1 mb-0 btn bg-gradient-success btn-sm" 
                    @click="backListButtonClick" 
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <GemMessage ref="gemMessageRef"  :messages="messages"  ></GemMessage>
            <Form
                role="form"
                class="text-start"
                :validation-schema="schema"
                @submit="confirmSave">

              <div class="row">

                <!-- Primera Columna -->
                <div class="col-md-6">
                  <label class="form-label mt-2 row mt-4">CUIT del municipio</label>
                  <Field v-model="organization.cuit" name="cuit" type="text" >
                    <soft-model-input
                        id="cuit"
                        v-model="organization.cuit"
                        name="cuit"
                        type="text"
                        placeholder="Ingrese el CUIT del municipio" />
                    <ErrorMessage name="cuit" class="text-danger" />
                  </Field>


                  <label class="form-label mt-2 row mt-4">CUIL del responsable</label>
                  <Field v-model="organization.user.cuil" name="user_cuil" type="text" >
                    <soft-model-input
                        id="user_cuil"
                        v-model="organization.user.cuil"
                        name="user_cuil"
                        type="text"
                        placeholder="Ingrese el CUIL del responsable" />
                    <ErrorMessage name="user_cuil" class="text-danger" />
                  </Field>

                  <label class="form-label mt-2 row mt-4">Correo electrónico </label>
                  <Field v-model="organization.user.email" name="email" type="email" >
                    <soft-model-input
                        id="email"
                        v-model="organization.user.email"
                        name="email"
                        type="email"
                        placeholder="Ingrese el correo electrónico" />
                    <ErrorMessage name="email" class="text-danger" />
                  </Field>

                  <label class="form-label mt-2 row mt-4">Contraseña del responsable</label>
                  <Field v-model="organization.user.password" name="user_password" type="password" >
                    <div class="input-group">
                      <input
                          id="user_password"
                          v-model="organization.user.password"
                          name="user_password"
                          :type="showPasswordResponsible ? 'text' : 'password'"
                          class="form-control form-control-lg"
                          placeholder="Ingrese la contraseña del responsable" />
                      <span class="input-group-text" style="cursor: pointer;" @click="togglePasswordResponsible">
                      <i :class="showPasswordResponsible ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                      </span>
                    </div>
                    <ErrorMessage name="user_password" class="text-danger" />
                  </Field>
                </div>

                <!-- Segunda Columna -->
                <div class="col-md-6">
                  <label class="form-label mt-2 row mt-4">Nombre del municipio</label>
                  <Field v-model="organization.name" name="name" type="text" >
                    <soft-model-input
                        id="name"
                        v-model="organization.name"
                        name="name"
                        type="text"
                        placeholder="Ingrese el nombre del municipio" />
                    <ErrorMessage name="name" class="text-danger" />
                  </Field>

                  <label class="form-label mt-2 row mt-4">Nombre</label>
                  <Field v-model="organization.user.name" name="nameUser" type="text" >
                    <soft-model-input
                        id="nameUser"
                        v-model="organization.user.name"
                        name="nameUser"
                        type="text"
                        placeholder="Ingrese el nombre del responsable" />
                    <ErrorMessage name="nameUser" class="text-danger" />
                  </Field>

                  <label class="form-label mt-2 row mt-4">Apellido</label>
                  <Field v-model="organization.user.last_name" name="last_name" type="text" >
                    <soft-model-input
                        id="last_name"
                        v-model="organization.user.last_name"
                        name="last_name"
                        type="text"
                        placeholder="Ingrese el apellido del responsable" />
                    <ErrorMessage name="last_name" class="text-danger" />
                  </Field>
                </div>
              </div>

              <h5 class="mt-4">Dominios y base de datos</h5>

              <div class="row">
                <div class="col-md-6">
                  <label class="form-label mt-2 row mt-4">Configuración del dominio</label>
                  <Field v-model="organization.domain" name="domain" type="text" >
                    <soft-model-input
                        id="organization_domain"
                        v-model="organization.domain"
                        name="domain"
                        type="text"
                        placeholder="Ingrese la configuración del dominio" />
                    <ErrorMessage name="domain" class="text-danger" />
                  </Field>
                </div>

                <div class="col-md-6">
                  <label class="form-label mt-2 row mt-4">Usuario de la base de datos</label>
                  <Field v-model="organization.tenancy_db_username" name="tenancy_db_username" type="text" >
                    <soft-model-input
                        id="tenancy_db_username"
                        v-model="organization.tenancy_db_username"
                        name="usuario_bd"
                        type="text"
                        placeholder="Ingrese el usuario de la BD" />
                    <ErrorMessage name="tenancy_db_username" class="text-danger" />
                  </Field>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <label class="form-label mt-2 row mt-4">Nombre de la base de datos</label>
                  <Field v-model="organization.tenancy_db_name" name="tenancy_db_name" type="text" >
                    <soft-model-input
                        id="tenancy_db_name"
                        v-model="organization.tenancy_db_name"
                        name="tenancy_db_name"
                        type="text"
                        placeholder="Ingrese el nombre de la BD" />
                    <ErrorMessage name="tenancy_db_name" class="text-danger" />
                  </Field>
                </div>

                <div class="col-md-6">
                  <label class="form-label mt-2 row mt-4">Contraseña de la base de datos </label>
                  <Field v-model="organization.tenancy_db_password" name="tenancy_db_password" type="password" >
                    <div class="input-group">
                    <input
                        id="tenancy_db_password"
                        v-model="organization.tenancy_db_password"
                        :type="showPassword ? 'text' : 'password'"
                        class="form-control form-control-lg"
                        name="tenancy_db_password"
                        placeholder="Ingrese la contraseña de la BD" />
                    <span class="input-group-text" style="cursor: pointer;" @click="togglePassword">
                    <i :class="showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                    </span>
                    </div>
                    <ErrorMessage name="tenancy_db_password" class="text-danger" />
                  </Field>
                </div>
              </div>

              <Button
                  class="float-end mt-4 mb-1 mr-1 btn btn-gradient-black"
                  severity="contrast"
                  label="Cancelar"
                  :disabled="loading"
                  type="button"
                  @click="cancelSave"
              >
                <template v-if="loading">
                  <span class="spinner-border spinner-border-sm"></span>
                </template>
              </Button>
              <Button
                  class="float-end mt-4 mb-0 mr-1 btn bg-gradient-success"
                  severity="contrast"
                  label="Guardar"
                  :disabled="loading"
                  type="submit"
              >
                <template v-if="loading">
                  <span class="spinner-border spinner-border-sm"></span>
                </template>
              </Button>
            </Form>

            <Dialog v-model:visible="confirmOrganizationDialog" :style="{ width: '450px' }" header="Confirmar" modal>
              <div class="confirmation-content">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                <span v-if="organization">Está seguro que desea guardar el municipio <b>{{ organization.name }}</b>?</span>
              </div>
              <template #footer>
                <Button label="Sí" icon="pi pi-check" text @click="handleRegister" />
                <Button label="No" icon="pi pi-times" text @click="confirmOrganizationDialog = false" />
              </template>
            </Dialog>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { useOrganizationStore } from '@/store/organizations/organization.module';
import SoftModelInput from "/src/components/SoftModelInput.vue";
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from "yup";
import GemMessage from "/src/components/GemMessage.vue";
import { useRouter } from 'vue-router';
import { useMainStore } from '@/store/index';

export default defineComponent({
  name: "OrganizationAdd",
  components: {
    SoftModelInput,
    Button,
    Form,
    Field,
    ErrorMessage,
    GemMessage,
    Dialog
  },
  setup() {
    const router = useRouter();
    const organizationStore = useOrganizationStore();
    const showPassword = ref(false);
    const showPasswordResponsible = ref(false);
    const messages = ref([]);
    const confirmOrganizationDialog = ref(false);
    const gemMessageRef = ref(null);
    const mainStore = useMainStore();
    const organization = ref({
      id: '',
      name: '',
      cuit: '',
      domain: '',
      tenancy_db_name: '',
      tenancy_db_username: '',
      tenancy_db_password: '',
      user: {
        cuil:'',
        name: '',
        last_name: '',
        email: '',
        password:''
      }
    });
    const loading = ref(false);
    const schema = yup.object().shape({
      cuit: yup.string()
          .required("Ingrese el Cuit del municipio.")
          .min(11, 'El CUIT debe tener exactamente 11 caracteres.')
          .max(11, 'El CUIT debe tener exactamente 11 caracteres.')
          .matches(/^\d{2}\d{8}\d{1}$/, 'El CUIT no debe contener caracteres especiales ni letras ni espacios.'),
      name: yup.string()
          .required("Ingrese el nombre del municipio.")
          .matches(/^[a-zA-ZáéíóúÁÉÍÓÚÜü\s]+$/, 'El nombre del municipio solo puede contener letras y espacios.'),
      nameUser: yup.string()
          .required("Ingrese el nombre del responsable.")
          .matches(/^[a-zA-ZáéíóúÁÉÍÓÚÜü\s]+$/, 'El nombre del responsable solo puede contener letras y espacios.'),
      last_name: yup.string()
          .required("Ingrese el apellido del responsable.")
          .matches(/^[a-zA-ZáéíóúÁÉÍÓÚÜü\s]+$/, 'El apellido del responsable solo puede contener letras y espacios.'),
      user_cuil: yup.string()
          .required("Ingrese el Cuil del responsable.")
          .min(11, 'El CUIL debe tener exactamente 11 caracteres.')
          .max(11, 'El CUIL debe tener exactamente 11 caracteres.')
          .matches(/^\d{2}\d{8}\d{1}$/, 'El CUIL no debe contener caracteres especiales, letras ni espacios.'),
      email: yup.string().email("Ingrese un correo válido").required("Ingrese el correo electrónico."),
      user_password: yup
          .string()
          .min(8, 'El password debe tener al menos 8 caracteres.')
          .matches(/[A-Z]/, 'El password debe contener al menos una letra mayúscula.')
          .matches(/[a-z]/, 'El password debe contener al menos una letra minúscula.')
          .matches(/\d/, 'El password debe contener al menos un número.')
          .matches(/[\W_]/, 'El password debe contener al menos un carácter especial.')
          .required('Ingrese la contraseña del responsable.'),
      domain: yup.string()
          .required("Ingrese la configuración del dominio.")
          .matches(
              /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z]{2,}(?:\.com|\.ar|\.es|\.co|\.net|\.org|\.gov|\.edu|\.mx|\.br|\.cl|\.uy)?$/,
              'Ingrese un dominio válido, como por ejemplo: "geminis.cba.gov.ar"'
          ),
      tenancy_db_username: yup.string()
          .required("Ingrese el usuario de la BD.")
          .matches(/^[a-z]+$/, 'El usuario de la BD solo puede contener letras en minusculas, sin espacios.'),
      tenancy_db_name: yup.string()
          .required("Ingrese el nombre de la BD.")
          .matches(
              /^[a-z]+$/,
              "El nombre de la BD solamente debe estar en minúscula sin espacios, números ni caracteres especiales."
          ),
      tenancy_db_password: yup
          .string()
          .min(8, 'El password debe tener al menos 8 caracteres.')
          .matches(/[A-Z]/, 'El password debe contener al menos una letra mayúscula.')
          .matches(/[a-z]/, 'El password debe contener al menos una letra minúscula.')
          .matches(/\d/, 'El password debe contener al menos un número.')
          .matches(/[\W_]/, 'El password debe contener al menos un carácter especial.')
          .required('Ingrese la contraseña del responsable.'),
    });

    const generateNameInsensitive = (name) => {
      // Se lista las palabras que deben permanecer en minúsculas
      const lowerCaseWords = ['y', 'de', 'del'];

      return name
          .toLowerCase() // Convierte todo a minúsculas
          .replace(/[^a-záéíóúüñ0-9\s]/gi, '') // Remueve caracteres no deseados, permitiendo espacios
          .split(' ') // Divide el string por espacios
          .map((word, index) => {
            // Si la palabra está en la lista de excepciones y no es la primera palabra, manténla en minúsculas
            if (lowerCaseWords.includes(word) && index !== 0) {
              return word;
            }
            // Capitaliza la primera letra de las demás palabras
            return word.charAt(0).toUpperCase() + word.slice(1);
          })
          .join(' '); // Vuelve a unir las palabras
    };

    const togglePassword = () => {
      showPassword.value = !showPassword.value;
    };

    const togglePasswordResponsible = () => {
      showPasswordResponsible.value = !showPasswordResponsible.value;
    };


    const confirmSave = () => {
      confirmOrganizationDialog.value = true;
    };
    
    const cancelSave = () => {
      router.push('/organization/list');
    };

    const backListButtonClick = () => {
      router.push('/organization/list');
    }

    const handleRegister = async () => {

      try {
        loading.value = true;
        confirmOrganizationDialog.value = false;
        organization.value.name = generateNameInsensitive(organization.value.name);
        organization.value.id = organization.value.tenancy_db_name;

        await organizationStore.addOrganization(organization.value);

        const newMessage = {
          id: messages.value.length + 1,
          content: `El municipio con nombre: ${organization.value.name} se está generando.`,
          severity: 'success',
          code: 1
        };
        mainStore.setMessage(newMessage);
        await router.push('/organization/list');
        loading.value = false;
      } catch (error) {

        if (gemMessageRef.value) {
          gemMessageRef.value.processMessages(error);
        }

        loading.value = false;
      }
    }

    return {
      schema,
      organization,
      loading,
      showPassword,
      togglePassword,
      showPasswordResponsible,
      togglePasswordResponsible,
      messages,
      organizationStore,
      handleRegister,
      backListButtonClick,
      confirmSave,
      cancelSave,
      confirmOrganizationDialog,
      gemMessageRef,
      mainStore
    };
  },
});
</script>
