import {defineStore} from 'pinia';
import userService from '../../services/users/usersadd.service';

export const useUserStore = defineStore('userStoreAdd', {
    state: () => ({
        user: [],
        allRoles: [],
    }),
    getters: {
        users: (state) => state.user
    },
    actions: {

        async addUser(user) {
            const response = await userService.addUsers(user);
            return response;
        },


        async getAllRolesAdd() {
            const roles = await userService.getAllRolesAdd();
            this.allRoles = roles.data.data.items;
        }
    }

});