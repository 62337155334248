<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="pb-0 card-header">
            <GemMessage :messages="messages"></GemMessage>
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Modificar Municipio: {{ organization?.name }}</h5>
              </div>
              <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <Button
                      type="button"
                      label="Volver al listado"
                      class="mx-1 mb-0 btn bg-gradient-success btn-sm"
                      @click="backOrganizationList"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <Form :validation-schema="schema" @submit="confirmSave">
              <div v-if="organization">
                <div class="row">
                  <div class="col-md-6">
                    <label class="form-label mt-2 row mt-4">CUIT del municipio</label>
                    <Field v-model="organization.cuit" name="cuit" type="text" >
                      <soft-model-input
                          id="cuit"
                          v-model="organization.cuit"
                          name="cuit"
                          type="text"
                          placeholder="Ingrese el CUIT del municipio" />
                      <ErrorMessage name="cuit" class="text-danger" />
                    </Field>
                  </div>

                  <div class="col-md-6">
                    <label class="form-label mt-2 row mt-4">Nombre del municipio</label>
                    <Field v-model="organization.name" name="name" type="text" >
                      <soft-model-input
                          id="name"
                          v-model="organization.name"
                          name="name"
                          type="text"
                          placeholder="Ingrese el nombre del municipio" />
                      <ErrorMessage name="name" class="text-danger" />
                    </Field>
                  </div>
                </div>

                <h5 class="mt-4">Dominios y base de datos</h5>

                <div class="row">
                  <div class="col-md-6">
                    <label class="form-label mt-2 row mt-4">Configuración del dominio</label>
                    <Field v-model="organization.domain" name="domain" type="text" >
                      <soft-model-input
                          id="organization_domain"
                          v-model="organization.domain"
                          name="domain"
                          type="text"
                          placeholder="Ingrese la configuración del dominio" />
                      <ErrorMessage name="domain" class="text-danger" />
                    </Field>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <label class="form-label mt-2 row mt-4">Nombre de la BD</label>
                    <p class="m-lg-1">{{ organization.id }}</p>
                  </div>
                </div>

                <div class="mt-4">
                  <Button
                      class="float-end mt-4 mb-1 mr-1 btn btn-gradient-black"
                      severity="contrast"
                      label="Cancelar"
                      :disabled="loading"
                      type="button"
                      @click="cancelSave"
                  >
                    <template v-if="loading">
                      <span class="spinner-border spinner-border-sm"></span>
                    </template>
                  </Button>
                  <Button
                      class="float-end mt-4 mb-0 mr-1 btn bg-gradient-success"
                      severity="contrast"
                      label="Guardar"
                      :disabled="loading"
                      type="submit"
                  >
                    <template v-if="loading">
                      <span class="spinner-border spinner-border-sm"></span>
                    </template>
                  </Button>
                </div>
              </div>
              <div v-else>
                <p>Cargando...</p>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>

    <!-- Diálogo de Confirmación -->
    <Dialog v-model:visible="confirmDialogVisible" :style="{ width: '450px' }" header="Confirmar" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3 mb-2" style="font-size: 2.5rem"></i>
        <span>¿Está seguro que desea modificar el municipio <b>{{ organization.name }}</b>?</span>
      </div>
      <template #footer>
        <Button label="Sí" icon="pi pi-check" text @click="handleSave" />
        <Button label="No" icon="pi pi-times" text @click="confirmDialogVisible = false" />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { useRoute, useRouter } from 'vue-router';
import { useEditOrganizationStore } from '@/store/organizations/organizationedit.module';
import GemMessage from "/src/components/GemMessage.vue";
import SoftModelInput from "/src/components/SoftModelInput.vue";
import { onMounted, computed, ref } from 'vue';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';

export default {
  name: 'OrganizationEdit',
  components: {
    Form,
    SoftModelInput,
    GemMessage,
    Button,
    Dialog,
    Field,
    ErrorMessage,
  },
  setup() {
    const showPassword = ref(false);
    const messages = ref([]);
    const route = useRoute();
    const router = useRouter();
    const organizationStore = useEditOrganizationStore();
    const organizationId = computed(() => route.query.tenant);
    const organization = computed(() => organizationStore.selectedOrganization || {});
    const confirmDialogVisible = ref(false);
    const loading = ref(false);

    const schema = yup.object().shape({
      cuit: yup
          .string()
          .required('Ingrese el CUIT del municipio')
          .min(11, 'El CUIT debe tener exactamente 11 caracteres')
          .max(11, 'El CUIT debe tener exactamente 11 caracteres')
          .matches(/^\d{2}\d{8}\d{1}$/, 'El CUIT no debe contener caracteres especiales ni letras ni espacios'),
      name: yup
          .string()
          .required('Ingrese el nombre del municipio')
          .min(4, 'El nombre del municipio debe contener al menos 4 caracteres')
          .matches(/^[a-zA-ZáéíóúÁÉÍÓÚÜü\s]+$/, 'El nombre solo puede contener letras y espacios'),
      domain: yup
          .string()
          .required('Ingrese la configuración del dominio')
          .matches(
              /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z]{2,}(?:\.com|\.ar|\.es|\.co|\.net|\.org|\.gov|\.edu|\.mx|\.br|\.cl|\.uy)?$/,
              'Ingrese un dominio válido, como por ejemplo: "geminis.cba.gov.ar"'
          ),
    });
    const generateNameInsensitive = (name) => {
      // Se lista las palabras que deben permanecer en minúsculas
      const lowerCaseWords = ['y', 'de', 'del'];

      return name
          .toLowerCase() // Convierte todo a minúsculas
          .replace(/[^a-záéíóúüñ0-9\s]/gi, '') // Remueve caracteres no deseados, permitiendo espacios
          .split(' ') // Divide el string por espacios
          .map((word, index) => {
            // Si la palabra está en la lista de excepciones y no es la primera palabra, manténla en minúsculas
            if (lowerCaseWords.includes(word) && index !== 0) {
              return word;
            }
            // Capitaliza la primera letra de las demás palabras
            return word.charAt(0).toUpperCase() + word.slice(1);
          })
          .join(' '); // Vuelve a unir las palabras
    };

    const fetchOrganization = async () => {
      try {
        if (organizationId.value) {
          await organizationStore.fetchOrganizationById(organizationId.value);
        }
      } catch (error) {
        handleErrors(error);
      }
    };

    const handleErrors = (error) => {
        messages.value = [];
      if (error.response?.data?.errors || error.response?.data?.success === false) {
        error.response.data.errors.forEach(err => {
          messages.value.push({
            id: messages.value.length + 1,
            content: err.message,
            severity: 'error',
            code: 99
          });
        });
      } else {
        messages.value.push({
          id: messages.value.length + 1,
          content: `${error.response?.statusText} - ${error.response?.data?.message}`,
          severity: 'error',
          code: error.response?.status
        });
      }
    };

    onMounted(fetchOrganization);

    const confirmSave = () => {
      confirmDialogVisible.value = true;
    };

    const togglePassword = () => {
      showPassword.value = !showPassword.value;
    };

    const handleSave = async () => {
      try {
        confirmDialogVisible.value = false;
        loading.value = true;
        organization.value.name = generateNameInsensitive(organization.value.name);
        await organizationStore.updateOrganization(organizationId.value, organization.value);
        messages.value = [];
        messages.value.push({
          id: messages.value.length + 1,
          content: 'Municipio actualizado con éxito',
          severity: 'success',
          code: 200
        });
      } catch (error) {
        handleErrors(error);
      } finally {
        loading.value = false;
      }
    };

    const cancelSave = () => {
      confirmDialogVisible.value = false;
      router.push({ name: 'OrganizationList' });
    };

    const backOrganizationList = () => {
      router.push('/organization/list');
    }

    return {
      schema,
      confirmSave,
      showPassword,
      organization,
      togglePassword,
      messages,
      confirmDialogVisible,
      loading,
      handleSave,
      cancelSave,
      backOrganizationList
    };
  }
};
</script>
