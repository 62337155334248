import { createRouter, createWebHistory } from "vue-router";

import UserList from "../views/dashboards/user/UserList.vue";
import UserDetail from "../views/dashboards/user/UserDetail.vue";
import UserAdd from "../views/dashboards/user/UserAdd.vue";
import UserEdit from "../views/dashboards/user/UserEdit.vue";
import UserOrganizationAdminList from "../views/dashboards/user/UserOrganizationAdminList.vue";
import OrganizationList from "../views/dashboards/organization/OrganizationList.vue";
import OrganizationNewAdmin from "../views/dashboards/organization/OrganizationNewAdmin.vue";
import OrganizationAdd from '../views/dashboards/organization/OrganizationAdd.vue';
import OrganizationEdit from '../views/dashboards/organization/OrganizationEdit.vue';
import OrganizationDetail from '../views/dashboards/organization/OrganizationDetail.vue';
import ProfileList from "../views/dashboards/profile/ProfileList.vue";
import ProfileAdd from "../views/dashboards/profile/ProfileAdd.vue";
import ProfileDetail from "../views/dashboards/profile/ProfileDetail.vue";
import ProfileEdit from "../views/dashboards/profile/ProfileEdit.vue";


import Login from "../views/auth/signin/Login.vue";
import Register from "../views/auth/signin/Login.vue";
import TenantLogin from "../views/auth/tenant/TenantLogin.vue";
import error404 from "../views/error/404.vue";
import NoAvailable from "../views/error/NoAvailable.vue";
import Home from "../views/Home.vue";
import guest from "../middlewares/guest.js";
import auth from "../middlewares/auth.js";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/Home",
  },
  {
    path: "/NoAvailable",
    name: "NoAvailable",
    component: NoAvailable,

  },
  {
    path: "/:pathMatch(.*)*",
    name: "error404",
    component: error404,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      middleware: [guest],
    },
  },
  {
    path: "/tenant/login",
    name: "TenantLogin",
    component: TenantLogin,
    meta: {
      middleware: [guest],
    },
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    meta: {
      middleware: [guest],
    },
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/user/list",
    name: "UserList",
    component: UserList,
    meta: {
      middleware: [auth],
      permissions: 'users_manage',
    },
  },
  {
    path: "/user/detail",
    name: "UserDetail",
    component: UserDetail,
    meta: {
      middleware: [auth],
      permissions: 'users_manage',
    },
  },
  {
    path: "/user/add",
    name: "UserAdd",
    component: UserAdd,
    meta: {
      middleware: [auth],
      permissions: 'users_create',
    },
  },
  {
    path: "/user/edit",
    name: "UserEdit",
    component: UserEdit,
    meta: {
      middleware: [auth],
      permissions: 'users_edit',
    },
  },
  {
    path: "/user/UserOrganizationAdminList",
    name: "ListAdmin",
    component: UserOrganizationAdminList,
    meta: {
      middleware: [auth],
      permissions: 'users_manage',
    },
  },
  {
    path: "/organization/list",
    name: "OrganizationList",
    component: OrganizationList,
    meta: {
      middleware: [auth],
      permissions: 'organizations_manage',
    },
  },
  {
    path: '/organizations/add',
    name: 'OrganizationAdd',
    component: OrganizationAdd,
    meta: {
      middleware: [auth],
      permissions: 'organizations_create',
    },
  },
  {
    path: '/organization/edit',
    name: 'OrganizationEdit',
    component: OrganizationEdit,
    meta: {
      middleware: [auth],
      permissions: 'organizations_manage',
    },
  },
  {
    path: "/organization/detail",
    name: "OrganizationDetail",
    component: OrganizationDetail,
    meta: {
      middleware: [auth],
      permissions: 'organizations_manage',
    },
  },
  {
    path: "/organization/newadminorganization",
    name: "Organization New Admin",
    component: OrganizationNewAdmin,
  },
  {
    path: "/profile/list",
    name: "ProfileList",
    component: ProfileList,
    meta: {
      middleware: [auth],
      permissions: 'profile_manage',
    },
  },
  {
    path: '/profile/add',
    name: 'ProfileAdd',
    component: ProfileAdd,
    meta: {
      middleware: [auth],
      permissions: 'profile_create',
    },
  },
  {
    path: "/profile/detail",
    name: "ProfileDetail",
    component: ProfileDetail,
    meta: {
      middleware: [auth],
      permissions: 'profile_manage',
    },
  },
  {
    path: "/profile/edit",
    name: "ProfileEdit",
    component: ProfileEdit,
    meta: {
      middleware: [auth],
      permissions: 'profile_edit',
    },
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

// IMPLEMENTACION DE MIDDLEWARE

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  

  
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    context.next(...parameters);
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}


router.beforeEach((to, from, next) => {

  if (!to.meta.middleware) {
    to.meta.middleware = [auth];
  }

  const permissions = to.meta.permissions;
  const middleware = to.meta.middleware;
  const context = {
    from,
    next,
    router,
    to,
  };
  const nextMiddleware = nextFactory(context, middleware, 1);

  return middleware[0]({ ...context, next: nextMiddleware , to: permissions });
});

// FIN IMPLEMENTACION DE MIDDLEWARE

export default router;
