import { defineStore } from 'pinia';
import profileEditService from '../../services/profiles/profileedit.service';

export const useProfileEditStore = defineStore({
  id: 'profileEdit',
  state: () => ({
    modulerolepermission:[],
    modulepermission:[],
    selectedrolepermission: null,
    modulerolePaginator: [],
    modulepermissionPaginator: [],
  }),
  actions: {
    async assignPermissionToRole(permissions) {
      const response = await profileEditService.assignPermissionsToRole(permissions);
      return response;
    },
    async fetchRoleModuleById(id, page, rows) {  //Dado un roleId busco todo los modulos asociados
      const response = await profileEditService.getRolesModulesById(id, page, rows);
      this.modulerolepermission = response.data.data;
      this.modulerolePaginator = response.data.data.pagination;
    },    
    async fetchPermissionsByModule(moduleId, page, rows) {
      const response = await profileEditService.getPermissionsByModule(moduleId, page, rows);
      this.modulepermission = response.data.data;
      this.modulepermissionPaginator = response.data.data.pagination;
    },
    async removePermissionToRole(permission) {
      await profileEditService.removePermissionToRole(permission); 
    }
  }
});
