import axiosInstance from '@/middlewares/interceptor.js';
import { useCookies } from 'vue3-cookies';

const { cookies } = useCookies();

export default {
  async getProfile() {

    try {
      let app_tenant = cookies.get("app_tenant");
      
      const response = axiosInstance.get(`api/${app_tenant}/users/roles-permissions`);
      
      return response;

    } catch (error) {
      console.error('Error fetching Profile:', error);
      throw error;
    }

  },

};
