import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import VueTilt from "vue-tilt.js";
import VueSweetalert2 from "vue-sweetalert2";
import SoftUIDashboard from "./soft-ui-dashboard";
import PrimeVue from 'primevue/config';
import { VueCookies } from 'vue3-cookies';
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "primevue/resources/themes/aura-light-green/theme.css";
import "primevue/resources/primevue.min.css";
import ToastService from 'primevue/toastservice';
import  { abilitiesPlugin }   from '@casl/vue';
import { usePermissionsStore } from '@/store/userProfile/permissions.module';

const appInstance = createApp(App);
const pinia  = createPinia();
appInstance.use(router);
appInstance.use(pinia);
appInstance.use(VueTilt);
appInstance.use(VueSweetalert2);
appInstance.use(SoftUIDashboard);
appInstance.use(PrimeVue);
appInstance.use(ToastService);
appInstance.use(VueCookies, {
  expireTimes: "30d",
  path: "/",
  domain: "",
  secure: true,
  sameSite: "None"
});
const permissionsStore = usePermissionsStore();
permissionsStore.defineAbilitiesFor({ role: 'guest' });
appInstance.use(abilitiesPlugin, permissionsStore.ability);
appInstance.mount("#app");