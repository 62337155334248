<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="pb-0 card-header">
            <GemMessage :messages="messages" ></GemMessage>
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Detalle del municipio: {{ organization?.name }}</h5>
              </div>
              <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <Button
                    label="Volver al listado"
                    class="mx-1 mb-0 btn bg-gradient-success btn-sm" 
                    @click="backListButtonClick" 
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div v-if="organization">
              <div class="row">
                <div class="col-md-6">
                  <label class="form-label mt-2 row mt-4">CUIT del municipio</label>
                  <p class="m-lg-1">{{ organization.cuit }}</p>
                </div>
                <div class="col-md-6">
                  <label class="form-label mt-2 row mt-4">Municipio</label>
                  <p class="m-lg-1">{{ organization.name }}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <label class="form-label mt-2 row mt-4">CUIL del responsable</label>
                  <p class="m-lg-1">{{  }}</p>
                </div>
                <div class="col-md-6">
                  <label class="form-label mt-2 row mt-4">Nombre</label>
                  <p class="m-lg-1">{{ }}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <label class="form-label mt-2 row mt-4">Correo electrónico</label>
                  <p class="m-lg-1">{{ }}</p>
                </div>
                <div class="col-md-6">
                  <label class="form-label mt-2 row mt-4">Apellido</label>
                  <p class="m-lg-1">{{  }}</p>
                </div>
              </div>

              <h5 class="mt-4">Dominios y base de datos</h5>

              <div class="row">
                <div class="col-md-6">
                  <label class="form-label mt-2 row mt-4">Configuración del dominio</label>
                  <p class="m-lg-1">{{ organization.domain }}</p>
                </div>
                <div class="col-md-6">
                  <label class="form-label mt-2 row mt-4">Usuario de la BD</label>
                  <p class="m-lg-1">{{ }}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <label class="form-label mt-2 row mt-4">Nombre de la BD</label>
                  <p class="m-lg-1">{{ organization.id }}</p>
                </div>
                <div class="col-md-6">
                  <label class="form-label mt-2 row mt-4">Contraseña de la BD</label>
                  <div class="input-group m-lg-1">
                    <input
                        :type="showPassword ? 'text' : 'password'"
                        v-model="organization.db_password"
                        class="form-control"
                        readonly
                    />
                    <span class="input-group-text" @click="togglePassword" style="cursor: pointer;">
                      <i :class="showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <p>Cargando...</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import { useOrganizationStore } from '@/store/organizations/organization.module';
import GemMessage from "/src/components/GemMessage.vue";
import Button from 'primevue/button';
import { onMounted, computed, ref } from 'vue';

export default {
  name: 'OrganizationDetail',
  components: {
    Button,
    GemMessage,
  },
  setup() {
    const showPassword = ref(false);
    const messages = ref([]);
    const router = useRouter();
    const organizationStore = useOrganizationStore();
    const urlParams = new URLSearchParams(window.location.search);
    const organizationId = urlParams.get('organizationId');

    const fetchOrganization = async () => {
      try {
        if (organizationId) {
          await organizationStore.fetchOrganizationById(organizationId);
        }
      } catch (error) {
        messages.value = [];
        if (error.response?.data?.errors || error.response?.data?.success === false) {
          error.response.data.errors.forEach(err => {
            messages.value.push({
              id: messages.value.length + 1,
              content: err.message,
              severity: 'error',
              code: 99
            });
          });
        } else {
          messages.value.push({
            id: messages.value.length + 1,
            content: `${error.response?.statusText} - ${error.response?.data?.message}`,
            severity: 'error',
            code: error.response?.status
          });
        }
      }
    };

    const backListButtonClick = () => {
      router.push('/organization/list');
    }

    onMounted(fetchOrganization);

    const organization = computed(() => organizationStore.selectedOrganization?.data || {});


    const togglePassword = () => {
      showPassword.value = !showPassword.value;
    };

    return {
      showPassword,
      organization,
      togglePassword,
      backListButtonClick,
      messages
    };
  }
};
</script>
