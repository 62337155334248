import axiosInstance from '@/middlewares/interceptor.js';
import { useCookies } from 'vue3-cookies';

const { cookies } = useCookies();

export default {
    async getUserDetail(id) {
        try {
            const app_tenant = cookies.get("app_tenant");
            const response = await axiosInstance.get(`api/${app_tenant}/users/${id}`);
            return response;
        } catch (error) {
            console.error('Error fetching user details:', error);
            throw error;
        }
    },

    async getAllRoles() {
        try {
            const app_tenant = cookies.get("app_tenant");
            const response = await axiosInstance.get(`api/${app_tenant}/roles`);
            return response;
        } catch (error) {
            console.error('Error fetching roles:', error);
            throw error;
        }
    },

    async updateUserRoles(userId, userData) {
        try {
            const app_tenant = cookies.get("app_tenant");
            await axiosInstance.put(`api/${app_tenant}/users/${userId}`, userData );
        } catch (error) {
            console.error('Error updating user roles:', error);
            throw error;
        }
    },
};
