import { defineStore } from 'pinia';
import { useCookies } from 'vue3-cookies';
import authService from '../../services/auth/auth.service';

const { cookies } = useCookies();

export const useAuthStore = defineStore('auth', {
  state: () => { 
    
    const user = cookies.get('user');

    return {
      user: user,
      loggedIn: !!user,
      tenantLogin: [],
    };
  },
  actions: {
    async login(userData) {
      const response = await authService.login(userData);
      
      if (response.data.status.success) {

        //Verificamos si no es un usuario tenancy
        if (response.data.data['x-data']) {
          
          cookies.set("app_tenant", 'tenant', "30d");
          
          if (response.data.data.items.length > 1) {
            response.data.data['user'] = userData;
          } else {

            cookies.set("tenant_id", response.data.data.items[0]['tenant_id'], "30d");

            const result = await authService.loginTenant(response.data.data['x-data']);
            cookies.set('user', JSON.stringify(userData), { expireTimes: "30d" });
            cookies.set("token", result.data.data.access_token, "30d");
            this.tenantLogin = result.data;
            return result;
          }
        } else {
          cookies.set('user', JSON.stringify(userData), { expireTimes: "30d" });
          cookies.set("token", response.data.data.access_token, "30d");
          cookies.set("app_tenant", 'central', "30d");
        } 
      }

      this.tenantLogin = response.data;
    },
    async loginTenant(xdataTenant) {
      const response = await authService.loginTenant(xdataTenant);
      cookies.set("token", response.data.data.access_token, "30d");
      this.loggedIn = true;
      this.tenantLogin = response.data.data;
    },
    async logout() {
      const response = await authService.logout();
      this.tenantLogin = response.data;
      this.loggedIn = false;
    },

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // IMPLEMENTACION REGISTER ///////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
 
    // async register(userData) {
    //   await authService.register(userData);
    //   this.loggedIn = true;
    //   // Optionally, you can set the user in localStorage here as well
    //   const { cookies } = useCookies();
    //   cookies.set('user', JSON.stringify(userData), { expireTimes: "30d" });
    // },
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // FIN IMPLEMENTACION REGISTER ///////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  },
  getters: {
    isLoggedIn: (state) => state.loggedIn,
  },
});