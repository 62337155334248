<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h4 class="mb-0">Nuevo perfil</h4>
              </div>
              <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <Button
                    type="button"
                    label="Volver al listado"
                    class="mx-1 mb-0 btn bg-gradient-success btn-sm" 
                    @click="handleBackButtonClick" 
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div v-if="loadingData">
              <p>Cargando...</p>
            </div>
            <div v-else>
              <GemMessage :messages="messages" ></GemMessage>
              <Form
                  role="form"
                  class="text-start"
                  @submit="confirmSave"
              >
                <div class="row">
                  <div class="col-md-6">
                    <label class="form-label mt-2 row mt-4">Nombre del perfil</label>
                    <InputText v-model="newProfile.display_name" placeholder="Ingrese el nombre del perfil" style="width: 100%" />
                    <div v-if="errors.name" class="text-danger">{{ errors.name }}</div>
                  </div>

                  <div class="col-md-6">
                    <label class="form-label mt-2 row mt-4">Descripción</label>
                    <InputText v-model="newProfile.description" placeholder="Ingrese la descripción" style="width: 100%" />
                    <div v-if="errors.description" class="text-danger">{{ errors.description }}</div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <label class="form-label mt-2 row mt-4">Submódulos</label>
                    <Dropdown
                        v-model="selectedModule"
                        :options="submodules"
                        option-label="name"
                        option-value="id"
                        placeholder="Seleccione un submódulo"
                        class="w-full md:w-56"
                    />
                  </div>
                </div>

                <h5 class="mt-4">Asignar permisos</h5>
                <div class="row">
                  <div class="col-md-12">
                    <DataTable
                        v-model:selection="selectedPermissions"
                        :value="filteredPermissions"
                        data-key="id"
                        show-gridlines
                        class="table table-flush"
                        table-style="min-width: 50rem"
                        selection-mode="multiple"
                        scrollable
                        scroll-height="400px"
                    >
                      <Column selection-mode="multiple" header-style="width: 3em"></Column>
                      <Column field="id" header="ID" hidden></Column>
                      <Column field="name" header="Permisos"></Column>
                      <Column field="description" header="Funcionalidad"></Column>
                    </DataTable>
                    <Button
                        class="float-end mt-4 mb-0 btn btn-gradient-black"
                        severity="contrast"
                        label="Asignar"
                        :disabled="loading"
                        @click="assignPermissions"
                    >
                      <template v-if="loading">
                        <span class="spinner-border spinner-border-sm"></span>
                      </template>
                    </Button>
                  </div>
                  <div v-if="errors.assignedPermissions" class="text-danger">{{ errors.assignedPermissions }}</div>
                </div>

                <h5 class="mt-4">Permisos seleccionados</h5>
                <div class="row">
                  <div class="col-md-12">
                    <DataTable
                        :value="assignedPermissions"
                        show-gridlines
                        class="table table-flush"
                        table-style="min-width: 50rem"
                        scrollable
                        scroll-height="400px"
                    >
                      <Column field="moduleName" header="Módulos"></Column>
                      <Column field="submoduleName" header="Submódulos"></Column>
                      <Column field="name" header="Permisos"></Column>
                      <Column field="description" header="Funcionalidad"></Column>
                      <Column header="Acción" style="min-width:8rem">
                        <template #body="slotProps">
                          <Button icon="pi pi-trash" outlined rounded severity="danger" @click="removePermission(slotProps.data.id)" />
                        </template>
                      </Column>
                    </DataTable>
                  </div>
                </div>
                <Button
                  class="float-end mt-4 mb-0 mr-1 btn btn-gradient-black"
                  severity="contrast"
                  label="Cancelar"
                  :disabled="loading"
                  type="button"
                  @click="cancelSavePermission"
                >
                  <template v-if="loading">
                    <span class="spinner-border spinner-border-sm"></span>
                  </template>
                </Button>
                <Button
                    class="float-end mt-4 mb-0 mr-1 btn bg-gradient-success"
                    severity="contrast"
                    label="Guardar"
                    :disabled="loading"
                    type="submit"
                >
                  <template v-if="loading">
                    <span class="spinner-border spinner-border-sm"></span>
                  </template>
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Dialog de confirmación para crear perfil -->
    <Dialog v-model:visible="createProfileDialog" :style="{ width: '450px' }" header="Confirmar" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3 mb-2" style="font-size: 2.5rem" />
        <span v-if="newProfile.display_name">¿Está seguro que desea guardar el perfil <b>{{ newProfile.display_name }}</b>?</span>
      </div>
      <template #footer>
        <Button label="Sí" icon="pi pi-check" text @click="handleRegister" />
        <Button label="No" icon="pi pi-times" text @click="createProfileDialog = false" />
      </template>
    </Dialog>

    <!-- Dialog de confirmación para volver al listado -->
    <Dialog v-model:visible="confirmBackDialogVisible" :style="{ width: '470px' }" header="Confirmar" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3 mb-2" style="font-size: 2.5rem" />
        <span>¿Está seguro que desea regresar al listado de perfiles?</span>
        <small>Se perderán los datos precargados.</small>
      </div>
      <template #footer>
        <Button label="Sí" icon="pi pi-check" text @click="confirmBackToList" />
        <Button label="No" icon="pi pi-times" text @click="confirmBackDialogVisible = false" />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { ref, watch, onMounted, computed } from 'vue';
import { useProfileAddStore } from '@/store/profiles/profileadd.module.js';
import { useModuleStore } from "@/store/modules/module.module";
import { useMainStore } from '@/store/index';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import Dialog from 'primevue/dialog';
import { Form } from 'vee-validate';
import { useRouter } from 'vue-router';
import GemMessage from "/src/components/GemMessage.vue";

export default {
  name: 'ProfileAdd',
  components: {
    Button,
    Dialog,
    InputText,
    Dropdown,
    DataTable,
    Column,
    Form,
    GemMessage,
  },
  setup() {
    const profileAddStore = useProfileAddStore();
    const mainStore = useMainStore();
    const router = useRouter();
    const storeModules = useModuleStore();
    const confirmBackDialogVisible = ref(false);
    const createProfileDialog = ref(false);
    const selectedModule = ref('');
    const selectedPermissions = ref([]);
    const assignedPermissions = ref([]);
    const messages = ref([]);
    const errors = ref({
      name: null,
      description: null,
      module: null,
    });

    const newProfile = ref({
      display_name: '',
      guard_name: 'web',
      description: '',
      permission: [],
    });

    const loading = ref(false);
    const loadingData = ref(true);

    const submodules = computed(() => storeModules.modules);
    const modulePermissions = computed(() => profileAddStore.allModulePermissions);
    const allPermissions = computed(() => profileAddStore.allPermissions);

    const validateForm = () => {
      errors.value = {
        display_name: null,
        description: null,
        assignedPermissions: null,
      };

      let valid = true;

      if (!newProfile.value.display_name) {
        errors.value.name = 'Ingrese el nombre del perfil';
        valid = false;
      } else if (!/^[a-zA-ZáéíóúÁÉÍÓÚÜü\s0-9]*$/.test(newProfile.value.display_name)) {
        errors.value.name = 'El nombre ingresado no es válido';
        valid = false;
      }

      if (!newProfile.value.description) {
        errors.value.description = 'Ingrese la descripción';
        valid = false;
      } else if (!/^[a-zA-ZáéíóúÁÉÍÓÚÜü\s0-9]*$/.test(newProfile.value.description)) {
        errors.value.description = 'La descripción ingresada no es válida';
        valid = false;
      }

      if (assignedPermissions.value.length === 0) {
        errors.value.assignedPermissions = 'Debe asignar al menos un permiso';
        valid = false;
      }

      return valid;
    };

    const generateNameFromDisplayName = (display_name) => {
      return display_name
          .toLowerCase()
          .replace(/\s+/g, '-')
          .replace(/[^a-z0-9-]/g, '');
    };

    const handleSubmit = async () => {
      if (validateForm()) {
        createProfileDialog.value = true;
      }
    };

    const assignPermissions = async () => {
      try {
        const enrichedPermissions = await Promise.all(
          selectedPermissions.value.map(async (permission) => {
            try {
              const module = await profileAddStore.getModuleById(permission.module_id);
              return {
                ...permission,
                moduleName: module ? module.name : 'N/A',
                submoduleName: module ? module.description : 'N/A'
              };
            } catch (error) {
              handleError(error);
              return {
                ...permission,
                moduleName: 'N/A',
                submoduleName: 'N/A'
              };
            }
          })
        );
        
        const uniquePermissions = enrichedPermissions.filter((permission) => {
          return !assignedPermissions.value.some(
            (assigned) => assigned.id === permission.id
          );
        });

        assignedPermissions.value = [
          ...assignedPermissions.value,
          ...uniquePermissions
        ];
        selectedPermissions.value = [];
      } catch (error) {
        console.error('Error en assignPermissions:', error);
      }
    };

    const removePermission = (permissionId) => {
      assignedPermissions.value = assignedPermissions.value.filter((permission) => permission.id !== permissionId);
    };

    const filteredPermissions = computed(() => {
      if (selectedModule.value) {
        return modulePermissions.value;
      }
      return allPermissions.value;
    });

    const loadModulePermissions = async (moduleId) => {
      try {
        await profileAddStore.fetchModulePermissions(moduleId);
      } catch (error) {
        handleError(error);
      }
    };

    const handleRegister = async () => {
      try {
        loading.value = true;
        newProfile.value.name = generateNameFromDisplayName(newProfile.value.display_name); 
        newProfile.value.moduleId = selectedModule.value;
        newProfile.value.permission = assignedPermissions.value.map((p) => ({ id: p.id, name: p.name }));
        await profileAddStore.createProfile(newProfile.value);
        messages.value = [];
        loading.value = false;
        const newMessage = {
          id: messages.value.length + 1,
          content: `Perfil con Nombre: ${newProfile.value.display_name}, Creado con éxito`,
          severity: 'success',
        };
        mainStore.setMessage(newMessage);
        router.push('/profile/list');
      } catch (error) {
        messages.value = [];
        loading.value = false;
        createProfileDialog.value = false;
        handleError(error);
      }
    };

    const confirmSave = () => {
      handleSubmit();
    };

    const handleBackButtonClick = () => {
      if (assignedPermissions.value.length > 0 && newProfile.value.display_name && newProfile.value.description) {
        confirmBackDialogVisible.value = true;
      } else {
        router.push('/profile/list');
      }
    };

    const confirmBackToList = () => {
      confirmBackDialogVisible.value = false;
      router.push('/profile/list');
    };

    const cancelSavePermission = () => {
      router.push('/profile/list');
    };

    const fetchProfileSubModules = async (page = 1, rows = 0) => {
      try {
        await storeModules.fetchSubModules(page, rows);
      } catch (error) {
        messages.value = [];
        const newMessage = {
          id: messages.value.length + 1,
          content: ` ${error.response?.statusText} - ${error.response?.status}`,
          severity: 'error',
          code: error.response?.status
        };
        messages.value.push(newMessage);
      }
    };

    const handleError = (error) => {
      messages.value = [];
      if (error.response?.data?.errors || error.response?.data?.success === false) {
        error.response.data.errors.forEach(err => {
          messages.value.push({
            id: messages.value.length + 1,
            content: err.message,
            severity: 'error',
            code: 99
          });
        });
      } else {
        messages.value.push({
          id: messages.value.length + 1,
          content: `${error.response?.statusText} - ${error.response?.data?.message}`,
          severity: 'error',
          code: error.response?.status
        });
      }
    };

    watch(selectedModule, async (newValue) => {
      if (newValue) {
        await loadModulePermissions(newValue);
      }
    });

    onMounted(async (page = 1, rows = 0) => {
      try {
        await fetchProfileSubModules(page, rows);
      } catch (error) {
        handleError(error);
      } finally {
        loadingData.value = false;
      }
    });

    return {
      confirmBackDialogVisible,
      createProfileDialog,
      newProfile,
      loading,
      loadingData,
      selectedModule,
      selectedPermissions,
      assignedPermissions,
      fetchProfileSubModules,
      errors,
      handleSubmit,
      assignPermissions,
      removePermission,
      submodules,
      modulePermissions,
      allPermissions,
      filteredPermissions,
      messages,
      generateNameFromDisplayName,
      validateForm,
      cancelSavePermission,
      handleRegister,
      handleBackButtonClick,
      confirmBackToList,
      confirmSave,
      mainStore,
    };
  },
};
</script>

<style scoped>
.confirmation-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
