import { useAuthStore } from "../store/auth/auth.module";

export default function guest({ next }) {

  const authStore = useAuthStore();

  if (authStore.isLoggedIn) {
    return next({ name: "Home" });
   }
  next();
}
