import { defineStore } from 'pinia';
import profileAddService from '../../services/profiles/profileadd.service';

export const useProfileAddStore = defineStore({
    id: 'profileAdd',
    state: () => ({
        selectedProfile: null,
        modulePermissions: []
    }),
    getters: {
        allSubmodules: (state) => state.submodules,
        allModulePermissions: (state) => state.modulePermissions
    },
    actions: {
        async fetchAllPermissions() {
            const response = await profileAddService.getAllresponse();
            this.allPermissions = response.data.data;
        },

        async fetchModulePermissions(SubmoduleId) {
            const response = await profileAddService.getModulePermissions(SubmoduleId);
            this.modulePermissions = response.data.data;
        },

        async createProfile(profileData) {
            const response = await profileAddService.createProfile(profileData);
            return response.data;
        },

        async getModuleById(moduleId) {
            const response = await profileAddService.getModuleById(moduleId);
            return response.data.data;
        }
    }
});
