import { useCookies } from 'vue3-cookies';
import axiosInstance from '@/middlewares/interceptor.js'

const { cookies } = useCookies();

export default {

  async getProfileDetailModulesById(id, page = 1, limit) {
    try {
      let app_tenant = cookies.get("app_tenant");

      const response = await axiosInstance.get(`api/${app_tenant}/roles/${id}/permissions?page=${page}&limit=${limit}`);
      return response;
    } catch (error) {
      console.error('Error fetching from API RolesModules/modules:', error);
      throw error;
    }
  },

};
