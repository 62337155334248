import axiosInstance from '@/middlewares/interceptor.js'

export default {
  async getOrganizations(page = 1, limit) {
    try {
      const response = await axiosInstance.get(`api/central/tenants?page=${page}&limit=${limit}`);
      return response;
    } catch (error) {
      console.error('Error fetching from API organizations/list:', error);
      throw error;
    }
  },

  async addOrganization(organization) {
    try {
      if (organization !== undefined) {
        const response = await axiosInstance.post(`api/central/tenants`, organization);
        return response;
      }
    } catch (error) {
      console.error('Error adding organization to API central/tenants:', error);
      throw error;
    }
  },

  async getOrganizationById(id) {
    try {
      const response = await axiosInstance.get(`api/central/tenants/${id}`);
      return response;
    } catch (error) {
      console.error('Error fetching from API organizations/get:', error);
      throw error;
    }
  },

  async deleteOrganization(OrganizationId) {
    try {
      const response = await axiosInstance.post(`api/central/tenants/${OrganizationId}/disable`);
      return response;
    } catch (error) {
      console.error('Error deleting Organization:', error);
      throw error;
    }
  },

  async restoreOrganization(OrganizationId) {
    try {
      const response = await axiosInstance.post(`api/central/tenants/${OrganizationId}/enable`);
      return response;
    } catch (error) {
      console.error('Error restoring Organization:', error);
      throw error;
    }
  }
};
