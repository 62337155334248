import axiosInstance from '@/middlewares/interceptor.js'


export default {

    async getOrganizationById(id) {
        try {
            const response = await axiosInstance.get(`api/central/tenants/${id}`);
            return response;
        } catch (error) {
            console.error('Error fetching organization detail from API:', error);
            throw error;
        }
    },

    async updateOrganization(id, organizationData) {
        try {
            const response = await axiosInstance.put(`api/central/tenants/${id}`, organizationData);
            return response;
        } catch (error) {
            console.error('Error updating organization roles:', error);
            throw error;
        }
    }
};