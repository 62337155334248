<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="pb-0 card-header">
            <GemMessage :messages="messages"></GemMessage>
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Modificar usuario: {{ user?.name }} {{user?.last_name}}</h5>
              </div>
              <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <Button
                    label="Volver al listado"
                    class="mx-1 mb-0 btn bg-gradient-success btn-sm" 
                    @click="backListButtonClick" 
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div v-if="user">
              <div class="row">
                <div class="col-md-6">
                  <label class="form-label mt-2 row mt-4">CUIL</label>
                  <p class="m-lg-1">{{ user.cuil }}</p>
                </div>
                <div class="col-md-6">
                  <label class="form-label mt-2 row mt-4">Nombre</label>
                  <p class="m-lg-1">{{ user.name }}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <label class="form-label mt-2 row mt-4">Correo electrónico</label>
                  <p class="m-lg-1">{{ user.email }}</p>
                </div>
                <div class="col-md-6">
                  <label class="form-label mt-2 row mt-4">Apellido</label>
                  <p class="m-lg-1">{{ user.last_name }}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <h5 class="mt-4 row mx-1">Asignar nuevos perfiles</h5>
                  <DataTable
                      v-model:selection="selectedRoles"
                      :value="filteredRoles"
                      data-key="id"
                      show-gridlines
                      class="table table-flush mt-3"
                      table-style="min-width: 50rem"
                      selection-mode="multiple"
                      scrollable
                      scroll-height="300px"
                  >
                    <Column selection-mode="multiple" header-style="width: 3em" :style="{'padding-left': '10px'}"></Column>
                    <Column field="display_name" header="Perfiles"  :style="{'padding-left': '10px'}"></Column>
                    <Column field="description" header="Descripción"  :style="{'padding-left': '10px'}"></Column>
                  </DataTable>
                  <Button
                      class="float-end mt-4 mb-1 mr-1 btn btn-gradient-black"
                      severity="contrast"
                      label="Asignar"
                      @click="assignRoles"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <h5 class="mt-4 row mx-1">Perfiles asignados</h5>
                  <DataTable
                      :value="assignedRoles"
                      data-key="id"
                      show-gridlines
                      class="table table-flush mt-3"
                      table-style="min-width: 50rem"
                      scrollable
                      scroll-height="300px"
                  >
                    <Column field="display_name" header="Perfiles" :style="{'padding-left': '10px'}"></Column>
                    <Column field="description" header="Descripción" :style="{'padding-left': '10px'}"></Column>
                    <Column header="Acción" style="min-width:8rem" :style="{'padding-left': '10px'}">
                      <template #body="slotProps">
                        <Button icon="pi pi-trash" outlined rounded severity="danger" @click="removeAssignedRole(slotProps.data.id)" />
                      </template>
                    </Column>
                  </DataTable>
                  <p v-if="errorMessage" class="text-danger">{{ errorMessage }}</p>
                </div>
              </div>
              <Button
                class="float-end mt-4 mb-1 mr-1 btn btn-gradient-black "
                severity="contrast"
                label="Cancelar"
                :disabled="loading"
                type="button"
                @click="cancelSave"
              >
                <template v-if="loading">
                  <span class="spinner-border spinner-border-sm"></span>
                </template>
              </Button>
              <Button
                  class="float-end mt-4 mb-0 mr-1 btn bg-gradient-success "
                  severity="contrast"
                  label="Guardar"
                  :disabled="loading"
                  @click="showConfirmDialog"
              >
              </Button>
            </div>
            <div v-else>
              <p>Cargando...</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Diálogo de Confirmación -->
    <Dialog v-model:visible="confirmDialogVisible" :style="{ width: '450px' }" header="Confirmar" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3 mb-2" style="font-size: 2.5rem" />
        <span>¿Está seguro que desea guardar los cambios en los perfiles asignados para <b>{{ user.name }} {{user.last_name}}</b>?</span>
      </div>
      <template #footer>
        <Button label="Sí" icon="pi pi-check" text @click="handleSave" />
        <Button label="No" icon="pi pi-times" text @click="confirmDialogVisible = false" />
      </template>
    </Dialog>
  </div>
</template>

<script>

import { useRouter } from 'vue-router';
import { useEditUserStore } from '@/store/users/usersedit.module';
import GemMessage from "/src/components/GemMessage.vue";
import Button from 'primevue/button';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dialog from 'primevue/dialog';
import { onMounted, computed, ref } from 'vue';

export default {
  name: 'EditUser',
  components: {
    GemMessage,
    Button,
    DataTable,
    Column,
    Dialog,
  },
  setup() {
    const messages = ref([]);
    const router = useRouter();
    const editUserStore = useEditUserStore();
    const loading = ref(false);
    const user = computed(() => editUserStore.selectedUser || {});
    const allRoles = computed(() => editUserStore.allRoles || []);
    const selectedRoles = ref([]);
    const assignedRoles = ref([]);
    const confirmDialogVisible = ref(false);
    const errorMessage = ref('');
    const urlParams = new URLSearchParams(window.location.search);
    const userId = urlParams.get('userId');

    const fetchUserEditDetail = async () => {
      try {
        if (userId) {
          await editUserStore.fetchUserEditDetailById(userId);
          assignedRoles.value = editUserStore.selectedUser.roles;
          selectedRoles.value = [...assignedRoles.value];
        }
      } catch (error) {
        messages.value = [];
        if (error.response?.data || error.response?.data?.success === false) {
          if (error.response.data?.status?.messages) {
            const newMessage = {
              id: messages.value.length + 1,
              content: ` ${error.response.data.status.messages}`,
              severity: 'error',
              code: 5
            };
            messages.value.push(newMessage);
          } else {
            const newMessage = {
              id: messages.value.length + 1,
              content: `${error.response?.statusText} `,
              severity: 'error',
              code: error.response?.status
            };
            messages.value.push(newMessage);
          }
        }
      }
    };

    const fetchRolesUserEdit = async () => {
      try {
        await editUserStore.fetchAllRolesUserEdit();
      } catch (error) {
        messages.value = [];
        if (error.response?.data || error.response?.data?.success === false) {
          if (error.response.data?.status?.messages) {
            const newMessage = {
              id: messages.value.length + 1,
              content: ` ${error.response.data.status.messages}`,
              severity: 'error',
              code: 5
            };
            messages.value.push(newMessage);
          } else {
            messages.value = [];
            const newMessage = {
              id: messages.value.length + 1,
              content: `${error.response?.statusText} `,
              severity: 'error',
              code: error.response?.status
            };
            messages.value.push(newMessage);
          }
        }
      }
    };

    const filteredRoles = computed(() => {
      return allRoles.value.filter(role =>
          !assignedRoles.value.some(assignedRole => assignedRole.id === role.id)
      );
    });

    const assignRoles = () => {
      errorMessage.value = '';
      const newRoles = selectedRoles.value.filter(role =>
          !assignedRoles.value.some(r => r.id === role.id)
      );
      assignedRoles.value = [...assignedRoles.value, ...newRoles];
      selectedRoles.value = [...assignedRoles.value];
    };

    const removeAssignedRole = (roleId) => {
      assignedRoles.value = assignedRoles.value.filter(role => role.id !== roleId);
      selectedRoles.value = selectedRoles.value.filter(role => role.id !== roleId);
    };

    const showConfirmDialog = () => {
      if (assignedRoles.value.length === 0) {
        errorMessage.value = 'Debe asignar al menos un perfil antes de guardar.';
        return;
      }
      confirmDialogVisible.value = true;
    };

    const cancelSave = () => {
      router.push('/user/list');
    };
    const backListButtonClick = () => {
      router.push('/user/list');
    };
    const handleSave = async () => {
      confirmDialogVisible.value = false;
      try {

        loading.value = true;        
        const updatedUserData = {
          cuil: user.value.cuil.trim(),
          roles: assignedRoles.value.map(role => role.name),
        };

        await editUserStore.updateUserEditRoles(userId, updatedUserData);
        messages.value = [];

        messages.value.push({
          id: messages.value.length + 1,
          content: 'Usuario modificado exitosamente',
          severity: 'success',
          code: 200
        });
        loading.value = false;
      } catch (error) {
        messages.value = [];
        if (error.response?.data || error.response?.data?.success === false) {
          if (error.response.data?.status?.messages) {
            const newMessage = {
              id: messages.value.length + 1,
              content: ` ${error.response?.data?.status?.messages}`,
              severity: 'error',
              code: 5
            };
            messages.value.push(newMessage);
          } else {
            const newMessage = {
              id: messages.value.length + 1,
              content: `${error.response?.statusText} `,
              severity: 'error',
              code: error.response?.status
            };
            messages.value.push(newMessage);
          }
        }
        loading.value = false;
      }
    };

    onMounted(async () => {
      await fetchUserEditDetail();
      await fetchRolesUserEdit();
    });

    return {
      user,
      messages,
      loading,
      filteredRoles,
      selectedRoles,
      assignedRoles,
      confirmDialogVisible,
      errorMessage,
      assignRoles,
      removeAssignedRole,
      showConfirmDialog,
      backListButtonClick,
      cancelSave,
      handleSave,
    };
  }
};
</script>

<style scoped>
.confirmation-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.text-danger {
  color: red;
}
</style>
