<template>
  <sidenav
      v-if="showSidenav"
      :custom-class="color"
      :class="[isTransparent, isRTL ? 'fixed-end' : 'fixed-start']"
  />
  <main
      class="main-content position-relative max-height-vh-100 h-100 border-radius-lg"
  >
    <navbar
        v-if="showNavbar"
        :class="[isNavFixed ? navbarFixed : '', isAbsolute ? absolute : '']"
        :text-white="isAbsolute ? 'text-white opacity-8' : ''"
        :min-nav="navbarMinimize"
    />
    <router-view />
    <app-footer v-show="showFooter" />
  </main>
</template>

<script>
import Sidenav from "@/components/Sidenav";
import Navbar from "@/components/Navbars/Navbar.vue";
import AppFooter from "@/components/Footer.vue";
import { useMainStore } from '@/store/index';

export default {
  name: "App",
  components: {
    Sidenav,
    Navbar,
    AppFooter,
  },
  setup() {
    const store = useMainStore();

    // Filtra propiedades que no comiencen con $ o _
    const filteredStore = Object.keys(store).reduce((acc, key) => {
      if (!key.startsWith('$') && !key.startsWith('_')) {
        acc[key] = store[key];
      }
      return acc;
    }, {});

    return {
      ...filteredStore,
    };
  },
};
</script>
<style>
#sidenav-main {
  overflow: hidden !important;
}

#sidenav-collapse-main {
  overflow-x: hidden !important;
}
</style>