import { defineStore } from 'pinia';
import profileService from '../../services/userProfile/profile.service';


export const useProfileStore = defineStore('profile', {
  state: () => ({
    profile_rol: [],
    profile_permissions : [],
  }),

  actions: {
    async getProfile() {

      const response =  await profileService.getProfile();
      this.profile_rol = response.data.data.roles;
      this.profile_permissions = response.data.data.permissions;
    },

  },
  getters: {
    roles: (state) => state.profile_rol,
    permissions: (state) => state.profile_permissions
  }
});