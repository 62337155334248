import { useAuthStore } from '../store/auth/auth.module';
import { useProfileStore } from '@/store/userProfile/profile.module';
import { usePermissionsStore } from '@/store/userProfile/permissions.module';
import { useAbility } from '@casl/vue';
 
export default async function auth({ next, to }) {
  
  try {
    const authStore = useAuthStore();
    const { can } = useAbility();

    const userProfileStore = useProfileStore();
    const permissionsStore = usePermissionsStore();

    await userProfileStore.getProfile();
    
    const role = userProfileStore.roles[0];
    const permissions = userProfileStore.permissions;
    
    permissionsStore.defineAbilitiesFor({ role, permissions });
    
    if (!authStore.isLoggedIn) {
      return next({ name: 'Login' });
    } else {
      if (to && !can(to)) {
        return next({ name: 'NoAvailable' });
      }
    }
    
    next();
    
  } catch (error) {
    console.error('Error al obtener los roles:', error);
    return next({ name: 'Login' });
  }
}
