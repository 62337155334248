<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Perfil detalles:</h5>
              </div>
              <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <Button
                    label="Volver al listado"
                    class="mx-1 mb-0 btn bg-gradient-success btn-sm" 
                    @click="backListButtonClick" 
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div v-if="loading">
              <p>Cargando...</p>
            </div>
            <div v-else>
              <div class="row">
                <div class="col-md-6">
                  <label class="form-label mt-2 row mt-4 media-font">Perfil</label>
                  <p class="m-lg-1">{{ profile[0]?.roles?.display_name }}</p>
                </div>
                <div class="col-md-6">
                  <label class="form-label mt-2 row mt-4 media-font">Descripción</label>
                  <p class="m-lg-1">{{ profile[0]?.roles?.description }}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="card-body">
                    <div class="table-responsive mt-4">
                      <GemMessage :messages="messages"></GemMessage>
                      <DataTable 
                        v-model:expandedRows="expandedRows" 
                        :value="profile" 
                        show-gridlines 
                        class="table table-flush" 
                        table-style="min-width: 50rem"
                        data-key="id"
                      >
                        <template #header>
                          <div class="flex flex-wrap justify-end gap-2">
                            <Button text icon="pi pi-plus" label="Expandir Todo" @click="expandAll" />
                            <Button text icon="pi pi-minus" label="Colapsar Todo" @click="collapseAll" />
                          </div>
                        </template>
                        <Column expander style="width: 5rem" />
                        <Column field="name" header="Módulo"></Column>
                        <Column field="description" header="Descripción"></Column>
                        <template #expansion="slotProps">
                          <div class="p-4">
                            <DataTable :value="slotProps.data.permissions">
                              <Column field="" style="width: 20%"></Column>
                              <Column field="" style="width: 20%"></Column>
                              <Column field="" style="width: 20%"></Column>
                              <Column field="description" header="Funcionalidad" style="width: 40%"></Column>
                            </DataTable>
                          </div>
                        </template>
                      </DataTable>
                      <Paginator
                        :template="{
                          default: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown',
                        }"
                        :rows="pagination.per_page"
                        :total-records="pagination.total"
                        :rows-per-page-options="[10,25,50,100]"
                        @page="onPageChange"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useProfileDetailStore } from "@/store/profiles/profiledetail.module";
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import GemMessage from "/src/components/GemMessage.vue";
import Paginator from 'primevue/paginator';

export default {
  name: "ProfileDetail",
  components: {
    DataTable,
    Column,
    Button,
    GemMessage,
    Paginator,
  },
  setup() {
    const profile = ref([]);
    const pagination = ref([]);
    const messages = ref([]);
    const loading = ref(true);
    const first = ref(0);
    const expandedRows = ref([]);
    const storeRoles = useProfileDetailStore();
    const router = useRouter();

    const fetchProfileDetail = async (page = 1, rows = 10) => {
      const urlParams = new URLSearchParams(window.location.search);
      const roleId = urlParams.get('roleId');

      if (roleId) {
        try {
          await storeRoles.fetchProfileModuleById(roleId, page, rows);
          profile.value = storeRoles.moduleprofiledetail.data;
          pagination.value = storeRoles.moduleprofiledetailpaginator;
        } catch (error) {
          messages.value = [];
          const newMessage = {
            id: messages.value.length + 1,
            content: ` ${error.response?.statusText} - ${ error.response?.status }`,
            severity: 'error',
            code: error.response?.status
          };
          messages.value.push(newMessage);
        } finally {
          loading.value = false;
        }
      } else {
        
        messages.value = [];
        const newMessage = {
          id: messages.value.length + 1,
          content: ` Falta el nombre de la entidad en la URL `,
          severity: 'error',
          code: 1
        };
        messages.value.push(newMessage);
        loading.value = false;
      }
    };

    const onPageChange = (event) => {
      fetchProfileDetail(event.page + 1, event.rows);
      first.value = event.first;
    };

    const expandAll = () => {
      expandedRows.value = storeRoles.moduleprofiledetail.data.map((p) => p.id);
    };

    const collapseAll = () => {
      expandedRows.value = [];
    };
    
    const backListButtonClick = () => {
      router.push('/profile/list');
    }

    return {
      storeRoles,
      fetchProfileDetail,
      onPageChange,
      messages,
      loading,
      profile,
      pagination,
      first,
      expandedRows,
      backListButtonClick,
      expandAll,
      collapseAll,
    };
  },

  mounted() {
    this.fetchProfileDetail();
  },
};
</script>

<style scoped>
.table thead th {
  background-color: #f8f9fa;
}
.table-cell {
  padding-left: 25px;
}
.small-font {
  font-size: 14px;
  font-weight: bold;
}
.media-font {
  font-size: 18px;
  font-weight: bold;
}
.large-font {
  font-size: 24px;
  font-weight: bold;
}
</style>
