import {defineStore} from 'pinia';
import organizationService from '@/services/organizations/organization.service';

export const useOrganizationStore = defineStore('organization', {
    state: () => ({
        organizations: [],
        organizationsPaginator: [],
        selectedOrganization: null,
    }),
    getters: {
        getOrganizations: (state) => state.organizations,
    },
    actions: {

        async fetchOrganizations(page, rows) {
            const response = await organizationService.getOrganizations(page, rows);
            this.organizations = response.data.data.items;
            this.organizationsPaginator = response.data.data.pagination;
        },

        async fetchOrganizationById(organizationId) {
            const response = await organizationService.getOrganizationById(organizationId);
            this.selectedOrganization = response.data;
        },

        async addOrganization(organization) {
            const response = await organizationService.addOrganization(organization);
            this.loading = false;
            return response;
        },

        async deleteOrganization(OrganizationId) {
            await organizationService.deleteOrganization(OrganizationId);
            const organization = this.organizations.find(org => org.id === OrganizationId);
            organization.is_active = false;
        },

        async restoreOrganization(OrganizationId) {
            await organizationService.restoreOrganization(OrganizationId);
            const organization = this.organizations.find(org => org.id === OrganizationId);
            organization.is_active = true;
        }
    }
});
