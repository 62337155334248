import { defineStore } from 'pinia';
import editUserService from '@/services/users/usersedit.service';

export const useEditUserStore = defineStore({
    id: 'editUser',
    state: () => ({
        selectedUser: null,
        allRoles: [],
    }),
    actions: {
        async fetchUserEditDetailById(id) {
            const response = await editUserService.getUserDetail(id);
            this.selectedUser = response.data.data;
        },
        async fetchAllRolesUserEdit() {
            const allRoles = await editUserService.getAllRoles();
            this.allRoles = allRoles.data.data.items;
        },
        async updateUserEditRoles(userId, userData) {
            await editUserService.updateUserRoles(userId, userData);
        },
    },
});
