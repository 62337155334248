import axios from 'axios';
import ApiBaseUrl from '@/services/url.service';
import { useCookies } from 'vue3-cookies';

const { cookies } = useCookies();

const axiosInstance = axios.create({
    baseURL: ApiBaseUrl,
    timeout: 10000,
    headers: {
        'Accept': '*/*',
        'Content-Type': 'application/json',
    },
    withCredentials: true,
    withXSRFToken: true
});

axiosInstance.interceptors.request.use(
    async function (config) {
        const token = cookies.get('token');
        const tenant = cookies.get('tenant_id');

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        if (tenant) {
            config.headers['X-Tenant'] = tenant;
        }

        if (config.method !== 'get') {
            const xsrfToken = cookies.get('XSRF-TOKEN');
            if (!xsrfToken) {
                await axiosInstance.get("/sanctum/csrf-cookie");
            }
            config.headers['X-XSRF-TOKEN'] = cookies.get('XSRF-TOKEN');
        }

        return config;
    }, function (err) {
        return Promise.reject(err);
    }
);

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response) {
            console.error(error.response);
            if (error.response.status === 419) {
                console.error('Token CSRF no válido o expirado');
            }
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
