<template>
  <div
      v-if="isLoggedIn"
      id="sidenav-collapse-main"
      class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100"

  >
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-collapse
            v-if="can('users_manage')"
            collapse-ref="dashboardsExamples"
            nav-text="Usuarios"
            :class="isActiveRoute(['user'])"
        >
          <template #icon>
            <Shop />
          </template>
          <template #list>
            <ul class="nav ms-4 ps-3">
<!--               nav links -->
              <sidenav-item
                  :to="{ name: 'UserList' }"
                  mini-icon="D"
                  text="Lista"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item">
        <sidenav-collapse
            collapse-ref="pagesExamples"
            nav-text="Administración"
            :class="isActiveRoute(['organization', 'profile', 'role'])">
          <template #icon>
            <Office />
          </template>
          <template #list>
            <ul class="nav ms-4 ps-3">
              <sidenav-item
                  v-if="can('organizations_manage') && tenantCentral"
                  :to="{ name: 'OrganizationList' }"
                  mini-icon="D"
                  text="Municipios"
              />
              <sidenav-item
                  v-if="can('profile_manage')"
                  :to="{ name: 'ProfileList' }"
                  mini-icon="D"
                  text="Perfiles"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
    </ul>
  </div>
</template>

<script>
import { computed, onMounted } from 'vue';
import { useAbility } from '@casl/vue';
import { useCookies } from 'vue3-cookies';
import { useAuthStore } from '@/store/auth/auth.module';
import { useProfileStore } from '@/store/userProfile/profile.module';
import { usePermissionsStore } from '@/store/userProfile/permissions.module';
import { useMainStore } from '@/store/index';
import SidenavItem from './SidenavItem.vue';
import SidenavCollapse from './SidenavCollapse.vue';
import Shop from '../../components/Icon/Shop.vue';
import Office from '../../components/Icon/Office.vue';

export default ({
  name: 'SidenavList',
  components: {
    SidenavItem,
    SidenavCollapse,
    Shop,
    Office,
  },
  props: {
    cardBg: {
      type: String,
      default: '',
    },
  },
  setup() {

    const { cookies } = useCookies();
    const { can } = useAbility();
    const store = useMainStore();
    const isRTL = computed(() => store.isRTL);

    const authStore = useAuthStore();

    const userProfileStore = useProfileStore();

    const permissionsStore = usePermissionsStore();
    
    const isLoggedIn = computed(() => authStore.isLoggedIn);

    const tenantCentral = computed(() => {
      if ( cookies.get('app_tenant') === 'central') {
        return true;
      }
      return false;
    });

    const isActiveRoute = (routes) => {
      const currentRoute = window.location.pathname.split('/')[1];
      return routes.includes(currentRoute) ? 'active' : '';
    };

    onMounted(async () => {
      if (authStore.isLoggedIn) {
        try {

          await userProfileStore.getProfile();

          const role = userProfileStore.roles[0];
          const permissions = userProfileStore.permissions;

          permissionsStore.defineAbilitiesFor({ role, permissions });

        } catch (error) {
          console.error('Error al obtener perfil o configurar permisos:', error);
        }
      }
    });

    return {
      isRTL,
      isLoggedIn,
      can,
      isActiveRoute,
      tenantCentral
    };
  },
});
</script>
