<template>
  <div>
    <div class="container">
      <GemMessage :messages="messages"></GemMessage>
      <div class="tenant-login-container mx-auto col-xl-6 col-lg-5 col-md-6">
        <Form @submit="handleTenantLogin">
          <div v-for="(tenant, index) in tenants" :key="index" class="text-center">
            <Button
              class="mb-0 btn bg-gradient-success my-4 mb-2 round-button"
              severity="contrast"
              :label="`Iniciar sesión en ${tenant.tenant_id}`"
              :disabled="loading"
              type="button"
              @click="selectTenant(tenant)"
            >
              <template v-if="loading && selectedTenant === tenant.tenant_id">
                <span class="spinner-border spinner-border-sm"></span>
              </template>
            </Button>
          </div>
          <div class="text-center">
            <Button
              class="mb-0 btn bg-gradient-success my-4 mb-2 round-button"
              severity="contrast"
              :label="`Volver al Login`"
              :disabled="loading"
              type="button"
              @click="goBack"
            >
              <template v-if="loading">
                <span class="spinner-border spinner-border-sm"></span>
              </template>
            </Button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref , onMounted} from 'vue';
import { useRouter } from 'vue-router';
import { useAuthStore } from "@/store/auth/auth.module";
import Button from 'primevue/button';
import GemMessage from "/src/components/GemMessage.vue";
import { Form } from "vee-validate"; 
import { useCookies } from 'vue3-cookies';

export default {
  name: "TenantLogin",
  components: {
    Form,
    Button,
    GemMessage,
  },
  setup() {
    const messages = ref([]);
    const loading = ref(false);
    const selectedTenant = ref(null);
    const userTenant = ref(null);
    const xdataTenant = ref(null);
    const router = useRouter();
    const storeAuth = useAuthStore();
    const { cookies } = useCookies();

    const user = storeAuth?.tenantLogin?.data?.user || null;
    const tenants = storeAuth?.tenantLogin?.data?.items || [];
    const tenantXdata = storeAuth?.tenantLogin?.data?.['x-data'] || {};

    const selectTenant = async (tenant) => {

      selectedTenant.value = tenant.tenant_id;
      userTenant.value = user;
      xdataTenant.value = tenantXdata;
      loading.value = true;
      
      try {

        cookies.set("tenant_id", selectedTenant.value, "30d");

        await storeAuth.loginTenant(xdataTenant.value);

        cookies.set('user', JSON.stringify(userTenant.value), { expireTimes: "30d" });  

        router.push("/home");

      } catch (error) {
        messages.value = [];
        const newMessage = {
          id: messages.value.length + 1,
          content: ` ${error.response?.statusText} - ${error.response?.status}`,
          severity: 'error',
          code: error.response?.status
        };
        messages.value.push(newMessage);
      } finally {
        loading.value = false;
        selectedTenant.value = null;
      }
    };

    const goBack = () => {
      router.push("/login");
    };

    onMounted(() => {

      if (!user){
        goBack();
      }

    });

    return {
      tenants,
      tenantXdata,
      goBack,
      messages,
      loading,
      selectedTenant,
      selectTenant,
    };
  },
};
</script>

<style scoped>
  .round-button {
    width: 70%;
  }
  .tenant-login-container {
    border: 2px solid #ccc;
    border-radius: 12px;
    padding: 2%;
    margin: 10% 0;
    background-color: #f9f9f9;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
</style>