import { defineStore } from 'pinia';
import moduleService from  "@/services/modules/module.service";

export const useModuleStore = defineStore({
    id: 'module',
    state: () => ({
        modules: [],
        selectedModule: null,
    }),
    getters: {
        allModules: (state) => state.modules,
    },
    actions: {
        async fetchAllModules() {
            try {
                const modules = await moduleService.getAllModules();
                this.modules = modules.data.data;
            } catch (error) {
                console.error('Error fetching all modules:', error);
                throw error;
            }
        },
        async fetchSubModules(page, rows) {
            try {
                const modules = await moduleService.getSubModules(page, rows);
                this.modules = modules.data.data;
            } catch (error) {
                console.error('Error fetching all modules:', error);
                throw error;
            }
        },
        async addModule(module) {
            try {
                const response = await moduleService.addModule(module);
                this.modules.push(response.data.data);
                return response;
            } catch (error) {
                console.error('Error adding module:', error);
                throw error;
            }
        },
        async fetchModuleById(id) {
            try {
                const response = await moduleService.getModuleById(id);
                this.selectedModule = response.data;
            } catch (error) {
                console.error('Error fetching module by id:', error);
                throw error;
            }
        },
        async updateModule(id, module) {
            try {
                const response = await moduleService.updateModule(id, module);
                const index = this.modules.findIndex(mod => mod.id === id);
                if (index !== -1) {
                    this.modules.splice(index, 1, response.data.data);
                }
                return response.data;
            } catch (error) {
                console.error('Error updating module:', error);
                throw error;
            }
        },
        async deleteModule(id) {
            try {
                await moduleService.deleteModule(id);
                this.modules = this.modules.filter(mod => mod.id !== id);
            } catch (error) {
                console.error('Error deleting module:', error);
                throw error;
            }
        },
        

    }
});