import { useCookies } from 'vue3-cookies';
import axiosInstance from '@/middlewares/interceptor.js'

const { cookies } = useCookies();

export default {

    async getUsers(page, limit) {
        try {
            let app_tenant = cookies.get("app_tenant");
            const response = await axiosInstance.get(`api/${app_tenant}/users?page=${page}&limit=${limit}`);
            return response;
        } catch (error) {
            console.error('Error fetching users from API:', error);
            throw error;
        }
    },
    
    async enableUser(user) {
        try {
            if (user !== undefined) {
                let app_tenant = cookies.get("app_tenant");
                const response = await axiosInstance.post(`api/${app_tenant}/users/${user}/enable`,user)
                return response;
            }
        } catch (error) {
            console.error('Error enable user from API:', error);
            throw error;
        }
    },

    async disableUser(user) {
        try {

            if (user !== undefined) {
                let app_tenant = cookies.get("app_tenant");
                const response = await axiosInstance.post(`api/${app_tenant}/users/${user}/disable`,user)
                return response;
            }
        } catch (error) {
            console.error('Error disable user from API:', error);
            throw error;
        }
    }
};
