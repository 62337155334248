import { defineStore } from 'pinia';
import { AbilityBuilder, Ability } from '@casl/ability';

export const usePermissionsStore = defineStore('permissions', {
    state: () => ({
        ability: new Ability([]),
        role: 'guest',
    }),
    actions: {
        defineAbilitiesFor({ role, permissions }) {
            const { can, rules } = new AbilityBuilder(Ability);
            this.role = role;

            if (role !== 'guest') {
                permissions.forEach(permission => {
                    can(permission);
                });
            }

            this.ability.update(rules);
        },

        setRole(role) {
            this.role = role;
        }
    }
});