<template>
  <aside
    v-if="isLoggedIn"
    id="sidenav-main"
    class="my-3 overflow-auto border-0 sidenav navbar navbar-vertical navbar-expand-xs border-radius-xl bg-color"
    data-color="info"
    :class="isRTL ? 'me-3 rotate-caret' : 'ms-3'"
  >
    <div class="sidenav-header">
      <i
          id="iconSidenav"
          class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"
          aria-hidden="true"
      ></i>
      <router-link class="m-0 navbar-brand" to="/">
        <img :src="logo" class="navbar-brand-img h-100" alt="main_logo" />
        <span class="ms-1 font-weight-bold">Géminis</span>
      </router-link>
    </div>
    <hr class="mt-0 horizontal dark" />
    <sidenav-list :card-bg="customClass" />
  </aside>
</template>

<script>
import { defineComponent, computed } from 'vue';
import { useAuthStore } from '@/store/auth/auth.module';
import { useMainStore } from '@/store/index';
import SidenavList from './SidenavList.vue';
import logo from '@/assets/img/logo-sh.png';

export default defineComponent({
  name: 'Index',
  components: {
    SidenavList,
  },
  props: {
    customClass: {
      type: String,
      default: '',
    },
  },
  setup() {
    const store = useMainStore();
    const isRTL = computed(() => store.isRTL);
    const authStore = useAuthStore();
    const isLoggedIn = computed(() => authStore.isLoggedIn);

    return {
      logo,
      isRTL,
      isLoggedIn,
    };
  },
});
</script>
