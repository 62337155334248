import axiosInstance from '@/middlewares/interceptor.js'


export default {

    async getUserOrganizationsAdmin(idOrganization, page, limit) {
        try {
            const response = await axiosInstance.get(`api/central/tenants/${idOrganization}/admins?page=${page}&limit=${limit}`);
            return response;
        } catch (error) {
            console.error('Error fetching from API Organization Admin List:', error);
            throw error;
        }
    },

    async setUserOrganizationAdmin(idOrganization, idUser) {
        try {
            const response = await axiosInstance.post(`api/central/tenants/${idOrganization}/admins/${idUser}/assign-main`);
            return response;

        } catch (error) {
            console.error('Error setting Admin Principal from API:', error);
            throw error;
        }
    },

    async enableUserOrganizationAdmin(idOrganization, idUser) {
        try {
            const response = await axiosInstance.post(`api/central/tenants/${idOrganization}/admins/${idUser}/enable`);
            return response;

        } catch (error) {
            console.error('Error setting enable user property from API:', error);
            throw error;
        }
    },

    async disableUserOrganizationAdmin(idOrganization, idUser) {
        try {
            const response = await axiosInstance.post(`api/central/tenants/${idOrganization}/admins/${idUser}/disable`);
            return response;

        } catch (error) {
            console.error('Error setting disable user property from API:', error);
            throw error;
        }
    }

};
