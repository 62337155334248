// stores/rolepermissions/rolepermission.module.js
import { defineStore } from 'pinia';
import rolePermissionService from '../../services/rolepermissions/rolepermission.service';

export const useRolePermissionStore = defineStore({
  id: 'rolePermission',
  state: () => ({
    rolepermission: [],
    modulerolepermission:[],
    modulepermission:[],
    selectedrolepermission: null,
    modulerolePaginator: [],
    rolepermissionPaginator:[],
    modulepermissionPaginator: [],

  }),
  getters: {
    rolepermissions: (state) => state.rolepermission,
  },
  actions: {
    async fetchRolePermissions(page, limit) {
      const response = await rolePermissionService.getRolePermissions(page, limit);
      this.rolepermission = response.data.data.items;
      this.rolepermissionPaginator = response.data.data.pagination;
    },
    async fetchgetListrolepermissionsAdmin() {
      try {
        const response = await rolePermissionService.getListRolesPermissionsAdmin();
        this.rolepermission = response.data;
      } catch (error) {
        console.error('Error fetching rolepermissions:', error);
        throw error;
      }
    },
    async addrolepermission(rolepermission) {
      try {
        const response = await rolePermissionService.addRolePermission(rolepermission);
        this.rolepermission.push(response);
        return response;
      } catch (error) {
        console.error('Error adding rolepermission:', error);
        throw error;
      }
    },
    async fetchrolepermissionById(id) {
      try {
        const response = await rolePermissionService.getRolesPermissionsById(id);
        this.selectedrolepermission = response.data;
      } catch (error) {
        console.error('Error fetching rolepermission:', error);
      }
    },
    async deleterolepermission(rolepermissionId) {
        await rolePermissionService.deleteRolePermission(rolepermissionId);
    },
    async restorerolepermission(rolepermissionId) {
      try {
        await rolePermissionService.restoreRolePermission(rolepermissionId);
        const rolepermission = this.rolepermission.find(p => p.rolepermission_name === rolepermissionId);
        if (rolepermission) {
          rolepermission.actived = true;
        }
      } catch (error) {
        console.error('Error restoring rolepermission:', error);
        throw error;
      }
    }
  }
});
