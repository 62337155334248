import { useCookies } from 'vue3-cookies';
import axiosInstance from '@/middlewares/interceptor.js'

const { cookies } = useCookies();

export default {
    async getUserDetail(id) {
        try {
            const app_tenant = cookies.get("app_tenant");
            const response = await axiosInstance.get(`api/${app_tenant}/users/${id}`);
            return response;
        } catch (error) {
            console.error('Error fetching user details:', error);
            throw error;
        }
    },
};
