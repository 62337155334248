import { useCookies } from 'vue3-cookies';
import axiosInstance from '@/middlewares/interceptor.js'

const { cookies } = useCookies();

export default {
  async assignPermissionsToRole(permission) {
    try {
      if (permission !== undefined) {
        let app_tenant = cookies.get("app_tenant");
        const response = await axiosInstance.post(`api/${app_tenant}/roles/assignpermissions`, permission)
        return response;
      }
    } catch (error) {
      console.error('Error deleting rolepermission:', error);
      throw error;
    }
  },
  async removePermissionToRole(permission) {
    try {
      let app_tenant = cookies.get("app_tenant");
      const response = await axiosInstance.post(`api/${app_tenant}/roles/removepermissions`, permission);
      return response;
    } catch (error) {
      console.error('Error deleting rolepermission:', error);
      throw error;
    }
  },
  async getRolesModulesById(id, page = 1, limit) {
    try {
      let app_tenant = cookies.get("app_tenant");
      const response = await axiosInstance.get(`api/${app_tenant}/roles/${id}/permissions?page=${page}&limit=${limit}`);
      return response;
    } catch (error) {
      console.error('Error fetching from API RolesModules/modules:', error);
      throw error;
    }
  },
  async getPermissionsByModule(moduleId, page = 1, limit) {
    try {
      let app_tenant = cookies.get("app_tenant");
        const response = await axiosInstance.get(`api/${app_tenant}/modules/${moduleId}/permissions?page=${page}&limit=${limit}`);
        return response;
    } catch (error) {
        console.error('Error fetching permissions by module:', error);
        throw error;
    }
  }
};
