import { useCookies } from 'vue3-cookies';
import axiosInstance from '@/middlewares/interceptor.js'

const { cookies } = useCookies();
export default {

    async addUsers(user) {
        try {
            if (user !== undefined) {
                let app_tenant = cookies.get("app_tenant");
                const response = await axiosInstance.post(`api/${app_tenant}/users`, user)
                return response;
            }
        } catch (error) {
            console.error('Error adding user from API:', error);
            throw error;
        }

    },

    async getAllRolesAdd() {
        try {
            const app_tenant = cookies.get("app_tenant");
            const response = await axiosInstance.get(`api/${app_tenant}/roles`);
            return response;
        } catch (error) {
            console.error('Error fetching roles:', error);
            throw error;
        }
    },

};
