<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="pb-0 card-header">
            <GemMessage :messages="messages"></GemMessage>
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Detalle del usuario: {{ user?.name }} {{ user?.last_name }}</h5>
              </div>
              <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <Button
                    label="Volver al listado"
                    class="mx-1 mb-0 btn bg-gradient-success btn-sm" 
                    @click="backListButtonClick" 
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div v-if="user">
              <div class="row">
                <div class="col-md-6">
                  <label class="form-label mt-2 row mt-4">CUIL</label>
                  <p class="m-lg-1">{{ user.cuil }}</p>
                </div>
                <div class="col-md-6">
                  <label class="form-label mt-2 row mt-4">Nombre</label>
                  <p class="m-lg-1">{{ user.name }}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <label class="form-label mt-2 row mt-4">Correo electrónico</label>
                  <p class="m-lg-1">{{ user.email }}</p>
                </div>
                <div class="col-md-6">
                  <label class="form-label mt-2 row mt-4">Apellido</label>
                  <p class="m-lg-1">{{ user.last_name }}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <label class="form-label mt-2 row mt-4">Perfiles</label>
                  <p class="m-lg-1">
                    <span v-for="role in user.roles" :key="role" class="badge bg-primary me-1">{{ role.display_name }}</span>
                  </p>
                </div>
              </div>
            </div>
            <div v-else>
              <p>Cargando...</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import { useUserDetailStore } from '@/store/users/usersdetail.module';
import Button from 'primevue/button';
import GemMessage from "/src/components/GemMessage.vue";
import { onMounted, computed, ref } from 'vue';

export default {
  name: 'UserDetail',
  components: {
    GemMessage,
    Button,
  },
  setup() {
    const messages = ref([]);
    const router = useRouter();
    const userDetailStore = useUserDetailStore();

    const fetchUserDetail = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const userId = urlParams.get('userId');

      try {
        if (userId) {
          await userDetailStore.fetchUserDetailById(userId);
        }
      } catch (error) {
        if (error.response?.data?.errors || error.response?.data?.success === false) {
          error.response.data.errors.forEach(err => {
            messages.value.push({
              id: messages.value.length + 1,
              content: err.message,
              severity: 'error',
              code: 99
            });
          });
        } else {
          messages.value.push({
            id: messages.value.length + 1,
            content: `${error.response?.statusText} - ${error.response?.data?.message}`,
            severity: 'error',
            code: error.response?.status
          });
        }
      }
    };

    const backListButtonClick = () => {
      router.push('/user/list');
    };

    onMounted(fetchUserDetail);

    const user = computed(() => userDetailStore.selectedUser || {});

    return {
      user,
      messages,
      backListButtonClick
    };
  }
};
</script>
