<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Administradores del municipio: {{ organizationName }}</h5>
              </div>
              <div class="my-auto mt-4 ms-auto mt-lg-0">
                <Button
                  label="Volver al listado"
                  class="mx-1 mb-0 btn bg-gradient-success btn-sm" 
                  @click="backListButtonClick" 
                />
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive mt-4">
              <GemMessage :messages="messages" />
              <DataTable
                  lazy
                  :value="usersOrganizationAdminStore.userOrganizationsAdmin"
                  showGridlines
                  class="table table-flush"
                  tableStyle="min-width: 50rem"
              >
                <template #empty> No se encontraron resultados. </template>
                <template #loading> Cargando resultados, por favor espere. </template>
                <Column field="cuil" header="Cuil" />
                <Column field="name" header="Nombre" />
                <Column field="last_name" header="Apellido" />
                <Column field="email" header="Correo" />
                <Column header="Roles">
                  <template #body="slotProps">
                    <span class="mt-3 me-1 badge badge-sm bg-info" v-for="rol in slotProps.data.roles" :key="rol.id">
                      {{ rol.display_name }}
                    </span>
                  </template>
                </Column>
                <Column header="Habilitado">
                  <template #body="slotProps">
                      <ToggleButton
                          :modelValue="slotProps.data.is_active"
                          data-bs-toggle="tooltip" title="Habilitar usuario"
                          @change="confirmToggleUserEnable(slotProps.data)"
                          onIcon="pi pi-check"
                          offIcon="pi pi-times"
                          onLabel="Habilitado"
                          offLabel="Deshabilitado"
                          :disabled="!can('users_edit')"
                      />
                  </template>
                </Column>
                <Column  header="Asignar como principal">
                  <template #body="slotProps">
                      <RadioButton
                          :value="slotProps.data"
                          data-bs-toggle="tooltip" title="Asignar usuario como principal"
                          v-model="adminUser"
                          @change="confirmToggleUserPrincipal(slotProps.data)"
                          :disabled="!slotProps.data.is_active || !can('users_edit')"
                      />
                  </template>
                </Column>
              </DataTable>
              <Paginator
                  :template="{
                  default: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown',
                }"
                  :rows="10"
                  :totalRecords="usersOrganizationAdminStore.userOrganizationsAdminPaginator.total"
                  :rowsPerPageOptions="[10, 25, 50, 100]"
                  @page="onPageChange"
              />
            </div>

            <Dialog v-model:visible="confirmEnableDialog" :style="{ width: '450px' }" header="Confirmar" :modal="true">
              <div class="confirmation-content">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                <span v-if="!selectedUser.is_active">Está seguro que desea <b>habilitar</b> el usuario <b>{{ selectedUser.name }}</b>?</span>
                <span v-else>Está seguro que desea <b>deshabilitar</b> el usuario <b>{{ selectedUser.name }}</b>?</span>
              </div>
              <template #footer>
                <Button label="Sí" icon="pi pi-check" text @click="enableUser" />
                <Button label="No" icon="pi pi-times" text @click="confirmEnableDialog = false" />
              </template>
            </Dialog>

            <Dialog v-model:visible="confirmAdminDialog" :style="{ width: '450px' }" header="Confirmar" :modal="true">
              <div class="confirmation-content">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                <span v-if="adminUser">Está seguro que desea seleccionar como principal el usuario <b>{{ adminUser.cuil }}</b>?</span>
              </div>
              <template #footer>
                <Button label="Sí" icon="pi pi-check" text @click="setAdminUser" />
                <Button label="No" icon="pi pi-times" text @click="confirmAdminDialog = false" />
              </template>
            </Dialog>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useUserOrganizationAdminStore } from '@/store/users/usersOrganizationAdmin.module';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import ToggleButton from 'primevue/togglebutton';
import Dialog from 'primevue/dialog';
import RadioButton from 'primevue/radiobutton';
import { useAbility } from '@casl/vue';
import GemMessage from '/src/components/GemMessage.vue';
import Paginator from 'primevue/paginator';

export default {
  components: {
    DataTable,
    Column,
    Button,
    ToggleButton,
    Dialog,
    RadioButton,
    GemMessage,
    Paginator
  },
  setup() {
    const router = useRouter();
    const usersOrganizationAdminStore = useUserOrganizationAdminStore();
    const selectedUser = ref(null);
    const adminUser = ref(null);
    const confirmAdminDialog = ref(false);
    const confirmEnableDialog = ref(false);
    const messages = ref([]);
    const first = ref(0);
    const { can } = useAbility();
    const organizationId = ref('');
    const organizationName = ref('');

    const onPageChange = (event) => {
      fetchUsersOrganizationAdmin(event.page + 1, event.rows);
      first.value = event.first;
    };

    const fetchUsersOrganizationAdmin = async (page = 1, rows = 10) => {
      const urlParams = new URLSearchParams(window.location.search);
      organizationId.value = urlParams.get('organizationId') || '';
      organizationName.value = urlParams.get('organizationName') || '';

      if (organizationId.value) {
        try {
          await usersOrganizationAdminStore.fetchUserOrganizationsAdmin(organizationId.value, page, rows);
          adminUser.value = usersOrganizationAdminStore.userOrganizationsAdminPrincipal;
        } catch (error) {
          messages.value = [];
          if (error.response?.data?.errors || error.response?.data?.success === false) {
            error.response.data.errors.forEach(err => {
              messages.value.push({
                id: messages.value.length + 1,
                content: err.message,
                severity: 'error',
                code: 99
              });
            });
          } else {
            messages.value.push({
              id: messages.value.length + 1,
              content: `${error.response?.statusText} - ${error.response?.status}`,
              severity: 'error',
              code: error.response?.status
            });
          }
        }
      }
    };

    const confirmToggleUserEnable = (user) => {
      if(user.main_tenant_admin ){
        messages.value.push({
          id: messages.value.length + 1,
          content: 'No es posible deshabilitar el usuario si es administrador del municipio',
          severity: 'warn',
          code: 20
        });
      }else{
        selectedUser.value = user;
        confirmEnableDialog.value = true;
      }
    };

    const enableUser = async () => {
      try {

        if(!selectedUser.value.is_active){
          await usersOrganizationAdminStore.enableUserOrganizationAdmin(organizationId.value, selectedUser.value.id);
        }else{
          await usersOrganizationAdminStore.disableUserOrganizationAdmin(organizationId.value, selectedUser.value.id);
        }

        messages.value = [];
        confirmEnableDialog.value = false;
        messages.value.push({
          id: messages.value.length + 1,
          content: `El usuario ${selectedUser.value.cuil} ha sido ${selectedUser.value.is_active ? 'habilitado' : 'deshabilitado'}`,
          severity: 'success'
        });

      } catch (error) {
        messages.value = [];
        if (error.response?.data?.errors || error.response?.data?.success === false) {
          error.response.data.errors.forEach(err => {
            messages.value.push({
              id: messages.value.length + 1,
              content: err.message,
              severity: 'error',
              code: 99
            });
          });
        } else {
          messages.value.push({
            id: messages.value.length + 1,
            content: `${error.response?.statusText} - ${error.response?.status}`,
            severity: 'error',
            code: error.response?.status
          });
        }
      }
    };

    const confirmToggleUserPrincipal = (user) => {

      if(!user.is_active){
        messages.value.push({
          id: messages.value.length + 1,
          content: 'No es posible asignar como administrador principal a un usuario deshabilitado',
          severity: 'warn',
          code: 20
        });
        adminUser.value = usersOrganizationAdminStore.userOrganizationsAdminPrincipal;

      }else{
        adminUser.value = user;
        confirmAdminDialog.value = true;
      }

    };

    const setAdminUser = async () => {
      try {
        await usersOrganizationAdminStore.setUserOrganizationAdmin( organizationId.value, adminUser.value.id );
        messages.value = [];
        confirmAdminDialog.value = false;
        messages.value.push({
          id: messages.value.length + 1,
          content: `El usuario ${adminUser.value.cuil} se ha establecido como principal`,
          severity: 'success'
        });
      } catch (error) {
        messages.value = [];
        if (error.response?.data?.errors || error.response?.data?.success === false) {
          error.response.data.errors.forEach(err => {
            messages.value.push({
              id: messages.value.length + 1,
              content: err.message,
              severity: 'error'
            });
          });
        } else {
          messages.value.push({
            id: messages.value.length + 1,
            content: `${error.response?.statusText} - ${error.response?.status}`,
            severity: 'error',
            code: error.response?.status
          });
        }
      }
    };

    const backListButtonClick = () => {
      router.push('/organization/list');
    }

    onMounted(() => {
      fetchUsersOrganizationAdmin();
    });

    return {
      organizationId,
      organizationName,
      selectedUser,
      adminUser,
      confirmAdminDialog,
      confirmEnableDialog,
      messages,
      first,
      usersOrganizationAdminStore,
      onPageChange,
      fetchUsersOrganizationAdmin,
      backListButtonClick,
      confirmToggleUserEnable,
      enableUser,
      confirmToggleUserPrincipal,
      setAdminUser,
      can
    };
  }
};
</script>

<style>
.p-message-close.p-link {
  margin-left: auto !important;
}

.thead-light th {
  background-color: #f8f9fa;
}
</style>
