import { useCookies } from 'vue3-cookies';
import axiosInstance from '@/middlewares/interceptor.js'

const { cookies } = useCookies();


export default {
    async getAllModules() {
        try {
            let app_tenant = cookies.get("app_tenant");
            const response = await axiosInstance.get(`api/${app_tenant}/modules?all=true`);
            return response;
        } catch (error) {
            console.error('Error fetching all modules:', error);
            throw error;
        }
    },
    async getSubModules(page = 1, limit = 0) {
        try {
            let app_tenant = cookies.get("app_tenant");
            const response = await axiosInstance.get(`api/${app_tenant}/submodules?page=${page}&limit=${limit}`);
            return response;
        } catch (error) {
            console.error('Error fetching from API RolesModules/modules:', error);
            throw error;
        }
    },
    async addModule(module) {
        try {
            let app_tenant = cookies.get("app_tenant");
            const response = await axiosInstance.post(`api/${app_tenant}/modules`, module);
            return response;
        } catch (error) {
            console.error('Error adding module:', error);
            throw error;
        }
    },

    async getModuleById(id) {
        try {
            let app_tenant = cookies.get("app_tenant");
            const response = await axiosInstance.get(`api/${app_tenant}/modules/${id}`);
            return response;
        } catch (error) {
            console.error('Error fetching module by id:', error);
            throw error;
        }
    },

    async updateModule(id, module) {
        try {
            let app_tenant = cookies.get("app_tenant");
            const response = await axiosInstance.put(`api/${app_tenant}/modules/${id}`, module);
            return response;
        } catch (error) {
            console.error('Error updating module:', error);
            throw error;
        }
    },

    async deleteModule(id) {
        try {
            let app_tenant = cookies.get("app_tenant");
            const response = await axiosInstance.delete(`api/${app_tenant}/modules/${id}`);
            return response;
        } catch (error) {
            console.error('Error deleting module:', error);
            throw error;
        }
    }
};