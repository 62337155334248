import {defineStore} from 'pinia';
import userListService from '../../services/users/userslist.service';

export const useUserListStore = defineStore('userStore', {
    state: () => ({
        usersList: [],
        usersPaginator: [],
        loading: false,
    }),
    getters: {
        users: (state) => state.usersList
    },
    actions: {
        async fetchUsers(page, rows) {
            const response = await userListService.getUsers(page, rows);
            this.usersList = response.data.data.items;
            this.usersPaginator = response.data.data.pagination;
        },

        async enableUser(id) {
            await userListService.enableUser(id);
            const user = this.usersList.find(usr => usr.id === id);
            user.is_active = true;
        },
        async disableUser(id) {
            await userListService.disableUser(id);
            const user = this.usersList.find(usr => usr.id === id);
            user.is_active = false;
        }
    }
});