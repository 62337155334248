<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Administración de municipios</h5>
              </div>
              <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <Button
                      label="+&nbsp;Nuevo"
                      class="mx-1 mb-0 btn bg-gradient-success btn-sm"
                      @click="navigateToOrganizationListAdmin"
                      :disabled="!can('organizations_create')"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive mt-4">
              <GemMessage :messages="messages" ></GemMessage>
              <DataTable :value="organizationStore.organizations" showGridlines class="table table-flush" tableStyle="min-width: 50rem">
                <template #empty>No se encontraron resultados</template>
                <template #loading>Cargando resultados, por favor espere...</template>
                <Column field="name" header="Municipio"></Column>
                <Column field="cuit" header="CUIT"></Column>
                <Column field="domain" header="Dominio"></Column>
                <Column field="is_active" header="Habilitado">
                  <template #body="slotProps">
                      <ToggleButton
                          :modelValue="slotProps.data.is_active"
                          data-bs-toggle="tooltip" title="Habilitar Municipio"
                          @change="confirmOrganizationActivation(slotProps.data)"
                          onIcon="pi pi-check"
                          offIcon="pi pi-times"
                          onLabel="Habilitado"
                          offLabel="Deshabilitado"
                          :disabled="!can('organization_edit')"
                      />
                  </template>
                </Column>
                <Column header="Estado" field="status"></Column>
                <Column header="Acciones" style="min-width:8rem">
                  <template #body="slotProps">
                    <Button
                        icon="pi pi-eye"
                        outlined
                        rounded
                        class="mr-2"
                        data-bs-toggle="tooltip" title="Ver Municipio"
                        @click="$router.push({ path: '/organization/detail', query: { organizationId: slotProps.data.id } })"
                    />
                      <Button icon="pi pi-pencil" outlined rounded class="mr-2" data-bs-toggle="tooltip" title="Editar Municipio" @click="editOrganization(slotProps.data.id)" :disabled="!can('organization_edit')" />
                      <Button icon="pi pi-building"  outlined rounded class="mr-2" data-bs-toggle="tooltip" title="Administradores del municipio" @click="$router.push({ path: '/user/userOrganizationAdminlist', query: { organizationId: slotProps.data.id, organizationName: slotProps.data.name } })" v-if="can('organization_edit')" :disabled="!can('organization_edit')" />
                  </template>
                </Column>
              </DataTable>
              <Paginator
                  :template="{
                  default: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown',
                }"
                  :rows="10"
                  :totalRecords="organizationStore.organizationsPaginator.total"
                  :rowsPerPageOptions="[10,25,50,100]"
                  @page="onPageChange"
              />
            </div>
            <Dialog v-model:visible="deleteOrganizationDialog" :style="{ width: '450px' }" header="Confirmar" modal>
              <div class="confirmation-content">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                <span v-if="selectedOrganization">Está seguro que desea eliminar el municipio <b>{{ selectedOrganization.name }}</b>?</span>
              </div>
              <template #footer>
                <Button label="Sí" icon="pi pi-check" text @click="deleteOrganization" />
                <Button label="No" icon="pi pi-times" text @click="deleteOrganizationDialog = false" />
              </template>
            </Dialog>
            <Dialog v-model:visible="activateOrganizationDialog" :style="{ width: '450px' }" header="Confirmar" modal>
              <div class="confirmation-content">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                <span v-if="selectedOrganization.is_active">Está seguro que desea <b>desactivar</b> el municipio <b>{{ selectedOrganization.name }}</b>?</span>
                <span v-else>Está seguro que desea <b>activar</b> el municipio <b>{{ selectedOrganization.name }}</b>?</span>
              </div>
              <template #footer>
                <Button label="Sí" icon="pi pi-check" text @click="activateOrganization" />
                <Button label="No" icon="pi pi-times" text @click="activateOrganizationDialog = false" />
              </template>
            </Dialog>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useOrganizationStore } from '@/store/organizations/organization.module';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import ToggleButton from 'primevue/togglebutton';
import Dialog from 'primevue/dialog';
import Paginator from 'primevue/paginator';
import GemMessage from "/src/components/GemMessage.vue";
import { useMainStore } from '@/store/index';
import { useAbility } from '@casl/vue';
import { useRouter } from 'vue-router';

export default {
  name: 'ListOrganization',
  components: {
    DataTable,
    Column,
    Button,
    ToggleButton,
    Dialog,
    Paginator,
    GemMessage
  },
  setup() {
    const organizationStore = useOrganizationStore();
    const mainStore = useMainStore();
    const selectedOrganization = ref(null);
    const deleteOrganizationDialog = ref(false);
    const activateOrganizationDialog = ref(false);
    const first = ref(0);
    const messages = ref(mainStore.message ? [mainStore.message] : []);
    const { can } = useAbility();
    const router = useRouter();


    const fetchOrganizations = async (page = 1, rows=10) => {
      try {
        await organizationStore.fetchOrganizations(page, rows);
        mainStore.clearMessage();
      } catch (error) {
        messages.value = [];
        const newMessage = {
          id: messages.value.length + 1,
          content: ` ${ error.response?.statusText } - ${ error.response?.status }`,
          severity: 'error',
          code: error.response?.status
        };
        messages.value.push(newMessage);

      }
    };

    onMounted(() => {
      fetchOrganizations();

    });

    const onPageChange = (event) => {
      fetchOrganizations(event.page + 1, event.rows);
      first.value = event.first;
    };

    const editOrganization = (tenant) => {
      router.push({ path: '/organization/edit', query: { tenant } });
    };

    const confirmOrganizationActivation = (data) => {
      selectedOrganization.value = data;
      activateOrganizationDialog.value = true;
    };

    const activateOrganization = async () => {
      activateOrganizationDialog.value = false;
      const organization = selectedOrganization.value;
      const id = organization.id;

      if (organization.is_active) {
        await deleteOrganization(id);
      } else {
        await enableOrganization(id);
      }

    };

    const confirmOrganizationDelete = (data) => {
      selectedOrganization.value = data;
      deleteOrganizationDialog.value = true;
    };

    const deleteOrganization = async () => {
      deleteOrganizationDialog.value = false;
      try {
        await organizationStore.deleteOrganization(selectedOrganization.value.id);
        messages.value = [];
        const newMessage = {
          id: messages.value.length + 1,
          content: `El municipio fue deshabilitado correctamente`,
          severity: 'success',
          code: 200
        };
        messages.value.push(newMessage);

      } catch (error) {
        messages.value = [];
        const newMessage = {
          id: messages.value.length + 1,
          content: ` ${ error.response?.statusText } - ${ error.response?.status }`,
          severity: 'error',
          code: error.response?.status
        };
        messages.value.push(newMessage);
      }
    };

    const enableOrganization = async (id) => {
      try {
        await organizationStore.restoreOrganization(id);
        messages.value = [];
        const newMessage = {
          id: messages.value.length + 1,
          content: `El municipio fue habilitado correctamente`,
          severity: 'success',
          code: 200
        };
        messages.value.push(newMessage);

      } catch (error) {
        messages.value = [];
        const newMessage = {
          id: messages.value.length + 1,
          content: ` ${ error.response?.statusText } - ${ error.response?.status }`,
          severity: 'error',
          code: error.response?.status
        };
        messages.value.push(newMessage);
      }
    };

    const navigateToOrganizationListAdmin = () => {
      router.push({name: 'OrganizationAdd'});
    };

    return {
      organizationStore,
      selectedOrganization,
      deleteOrganizationDialog,
      activateOrganizationDialog,
      activateOrganization,
      first,
      fetchOrganizations,
      onPageChange,
      editOrganization,
      confirmOrganizationActivation,
      confirmOrganizationDelete,
      deleteOrganization,
      enableOrganization,
      navigateToOrganizationListAdmin,
      messages,
      can
    };


  },
};
</script>

<style>
.thead-light th {
  background-color: #f8f9fa;
}
.p-dropdown-items-wrapper ul{padding-left: 0 !important;}
</style>
