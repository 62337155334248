import { defineStore } from 'pinia';
import userDetailService from '@/services/users/usersdetail.service';

export const useUserDetailStore = defineStore({
    id: 'userDetail',
    state: () => ({
        selectedUser: null,
    }),
    getters: {
        userDetails: (state) => state.selectedUser,
    },
    actions: {
        async fetchUserDetailById(id) {
            const response = await userDetailService.getUserDetail(id);
            this.selectedUser = response.data.data;
        },
    }
});
