import Jsona from "jsona";
import { useCookies } from 'vue3-cookies';
import axiosInstance from '@/middlewares/interceptor.js'

const dataFormatter = new Jsona();
const { cookies } = useCookies();


export default {
  
  async getRolePermissions(page = 1, limit = 2) {
    try {
      let app_tenant = cookies.get("app_tenant");
      const response = await axiosInstance.get(`api/${app_tenant}/roles?page=${page}&limit=${limit}`);
      return response;
    } catch (error) {
      console.error('Error fetching from API RolesPermissions/list:', error);
      throw error;
    }
  },

  async addRolePermission(rolepermission) {
    try {
      if (rolepermission !== undefined) {
        rolepermission.type = 'RolesPermissions';
        let app_tenant = cookies.get("app_tenant");
        const newJson = dataFormatter.serialize({ stuff: rolepermission });
        const response = await axiosInstance.post(`api/${app_tenant}/roles/create`, newJson.data.attributes);
        return response;
      }
    } catch (error) {
      console.error('Error adding rolepermission to API rolepermission/save:', error);
      throw error;
    }
  },

  async getListRolesPermissionsAdmin() {
    try {
      let app_tenant = cookies.get("app_tenant");
      const response = await axiosInstance.get(`api/${app_tenant}/roles/listadmin`);
      return response;
    } catch (error) {
      console.error('Error fetching from API rolepermission/list:', error);
      throw error;
    }
  },
  async getRolesPermissionsById(id) {
    try {
      let app_tenant = cookies.get("app_tenant");
      const response = await axiosInstance.get(`api/${app_tenant}/roles/${id}`);
      return response;
    } catch (error) {
      console.error('Error fetching from API RolesPermissions/get:', error);
      throw error;
    }
  },
  async deleteRolePermission(rolepermissionId) {
    try {
      let app_tenant = cookies.get("app_tenant");
      const response = await axiosInstance.delete(`api/${app_tenant}/roles/${rolepermissionId}`);
      return response;
    } catch (error) {
      console.error('Error deleting rolepermission:', error);
      throw error;
    }
  },

  async restoreRolePermission(rolepermissionId) {
    try {
      let app_tenant = cookies.get("app_tenant");
      const response = await axiosInstance.post(`api/${app_tenant}/roles/restore`, { rolepermission_id: rolepermissionId });
      return response;
    } catch (error) {
      console.error('Error restoring rolepermission:', error);
      throw error;
    }
  }

};
