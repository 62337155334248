import { useCookies } from 'vue3-cookies';
import axiosInstance from '@/middlewares/interceptor.js'

const { cookies } = useCookies();

export default {
  
  async login(user) {
    try {
      
      if (user !== undefined) {
        const response = await axiosInstance.post("api/central/login", user);
        return response;
      }
    } catch (error) {
      console.error('Error Loging:', error);
      throw error;
    }
  },
  async loginTenant(xdataTenant) {
    try {
      
      if (xdataTenant !== undefined) {
        const response = await axiosInstance.post("api/tenant/login", {'x-data': xdataTenant});
        return response;
      }
    } catch (error) {
      console.error('Error Tenant Loging:', error);
      throw error;
    }
  },
  async logout() {
    try {
      let app_tenant = cookies.get("app_tenant");
      const response = await axiosInstance.post(`api/${app_tenant}/logout`, {});
      return response;
    } catch (error) {
      console.error('Error al cerrar sesión:', error);
      throw error;
    }
  },

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // IMPLEMENTACION LOGOUT / REGISTER ///////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////

  // async register(user) {
  //   const response = await axiosInstance.post("api/central/register", user);
  //   if (response.data.access_token) {
  //     cookies.set("user", response.data.access_token, "30d"); 
  //   }
  // },
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // FIN IMPLEMENTACION LOGOUT / REGISTER ///////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
};
