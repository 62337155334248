<template>

  <Toast v-if="useToast"/>
  <transition-group v-if="!useToast" name="p-message" tag="div">
    <div v-for="msg in localMessages" :key="msg.id">
      <Message
          closable
          :severity="msg.severity"
          class="mt-4"
          :life="5000"
          @close="handleCloseMessage(msg)"
      >
        {{ msg.content }}
      </Message>
    </div>
  </transition-group>
</template>

<script>
import Message from 'primevue/message';
import Toast from 'primevue/toast';

export default {
  components: {
    Message,
    Toast
  },
  props: {
    messages: {
      type: Array,
      default: () => []
    },
    life: {
      type: Number,
      default: 5000
    },
    useToast: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update-messages'],
  data() {
    return {
      localMessages: this.messages.length ? this.modifyMessages(this.messages) : []
    };
  },
  watch: {
    messages: {
      handler(newMessages) {
        this.localMessages = this.modifyMessages(newMessages);
      },
      deep: true,
      immediate: true
    }
  },
  methods: {

    showToast(msg) {

      this.$toast.removeAllGroups();

      this.$toast.add({
        severity: msg.severity,
        summary: 'Notificación',
        detail: msg.content,
        life: this.life,
        onClose: () => {
          this.handleCloseMessage(msg);
        }
      });


      setTimeout(() => {
        this.removeMessage(msg.id);
      }, this.life);


    },

    handleCloseMessage(msg) {
      this.removeMessage(msg.id);
      this.$emit('update-messages', this.localMessages);
    },

    modifyMessages(messages) {

      const errorMessages = {
        400: 'Solicitud incorrecta: La petición no pudo ser entendida por el servidor debido a una sintaxis malformada.',
        401: 'No autorizado: Se requiere autenticación para acceder al recurso solicitado.',
        403: 'Prohibido: No tienes permiso para acceder al recurso solicitado.',
        405: 'Método no permitido: El método de solicitud utilizado no está soportado para el recurso solicitado.',
        419: 'Página expirada: La página ha expirado debido a la inactividad. Por favor, refresca y vuelve a intentarlo.',
        502: 'Puerta de enlace incorrecta: El servidor, actuando como puerta de enlace o proxy, recibió una respuesta inválida del servidor ascendente al que accedió en nombre de la solicitud.',
        504: 'Tiempo de espera de la puerta de enlace: El servidor, actuando como puerta de enlace o proxy, no recibió una respuesta a tiempo del servidor ascendente al que accedió en nombre de la solicitud.',
        500: 'Error interno del servidor. Comuniquese con un administrador del sistema',
        503: 'El tiempo de respuesta del servidor ha excedido el límite permitido. Por favor, inténtelo más tarde.',
        408: 'El tiempo de respuesta del servidor ha excedido el límite permitido. Por favor, inténtelo más tarde.',
        404: 'Lo sentimos, ha ocurrido un error al cargar los datos. Por favor, inténtelo de nuevo más tarde. Si el problema persiste, comuníquese con el soporte técnico para obtener asistencia adicional.',
        422: 'Lo sentimos, el servidor no pudo procesar la solicitud porque contiene datos no válidos. Por favor, verifique si los datos que está enviando ya existen.',
      };

      const seen = new Set();

      return messages
          .map(msg => {
            const modifiedMsg = {
              ...msg,
              content: errorMessages[msg.code] ? errorMessages[msg.code] : msg.content,
              severity: msg.severity ? msg.severity : 'error',
            };

            modifiedMsg.timeoutId = setTimeout(() => {
              this.removeMessage(msg.id);
              this.$emit('update-messages', this.localMessages);
            }, this.life);

            if (this.useToast) {
              this.showToast(modifiedMsg);
            }

            return modifiedMsg;
          })
          .filter(msg => {
            const key = `${msg.code}-${msg.content}`;
            if (seen.has(key)) {
              return false;
            }
            seen.add(key);
            return true;
          });
    },

    processMessages(error) {

      if (error.response?.data?.status?.messages) {
        error.response.data.status.messages.forEach(err => {
          this.localMessages.push({
            id: this.localMessages.length + 1,
            content: err,
            severity: 'error'
          });
        });
      } else {
        this.localMessages.push({
          id: this.localMessages.length + 1,
          content: `${error.response?.statusText || 'Error'} - ${error.response?.status || 'N/A'}`,
          severity: 'error',
          code: error.response?.status || 0
        });
      }

      this.localMessages = this.modifyMessages(this.localMessages);

    },

    removeMessage(id) {
      const msgToRemove = this.localMessages.find(msg => msg.id === id);
      if (msgToRemove && msgToRemove.timeoutId) {
        clearTimeout(msgToRemove.timeoutId);
      }
      this.localMessages = this.localMessages.filter(msg => msg.id !== id);
    }

  }

};
</script>

<style scoped>
.p-message-enter-active, .p-message-leave-active {
  transition: opacity 0.5s;
}

.p-message-enter, .p-message-leave-to {
  opacity: 0;
}
</style>